@import "../../../assets/styles/abstracts/variables";

// Title Border
.profile {
  // Hidding header icons
  .main-header {
    .header-user {
      display: none;
    }
  }

  .page-title-border {
    width: 240px;
  }
}

// Main
.profile-main {
  min-height: calc(100vh - 200px);
  padding: 80px 30px 30px;
  position: relative;
}

// Avatar Highlight
.profile-highlight {
  background: $white-bg;
  border: 2px solid #707070;
  border-radius: 50%;
  height: 130px;
  left: 50%;
  object-fit: contain;
  position: absolute;
  top: -65px;
  transform: translateX(-50%);
  width: 130px;

  img {
    border-radius: 50%;
    height: 125px;
    object-fit: contain;
    width: 130px;
  }
}

// Button
.button-next {
  background: $orange-bg;
  color: $white-text;
  margin-top: 20px;
}

// Hair Color
.brown {
  fill: #d07b5f !important;
}

.black {
  fill: #28221f !important;
}

.blond {
  fill: #e0ae44 !important;
}

.darkBrown {
  fill: #6e4024 !important;
}

.redhead {
  fill: #f9693a !important;
}

.blue {
  fill: #3e68b2 !important;
}

// Skin colors
.skin1 {
  fill: #ffd8c4 !important;
}

.skin2 {
  fill: #ffc0a0 !important;
}

.skin3 {
  fill: #e1a27c !important;
}

.skin4 {
  fill: #bf8460 !important;
}

.skin5 {
  fill: #7e5237 !important;
}

.skin6 {
  fill: #f1c982 !important;
}
