@import '../../../../assets/styles/abstracts/variables';

// Main
.poderes-instrutor-main{
    padding: 70px 30px 30px;
    position: relative;

    &:after{
        background: url('../../../../assets/images/instrutor/turma-icon.png');
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 50%;
        content: '';
        height: 110px;
        left: 50%;
        position: absolute;
        top: -55px;
        transform: translateX(-50%);
        width: 110px;
    }
}

// Nome da turma
.poderes-instrutor-class-name{
    border-bottom: 1px solid rgba(112, 112, 112, .35);
    color: $blue-title;
    font-family: 'DK Aderyn', sans-serif;
    font-size: 26px;
    letter-spacing: 1.3px;
    line-height: 25px;
    margin: 0;
    padding-bottom: 10px;

    span{
        color: $link-text;
        display: block;
        font-family: 'Muli Light', sans-serif;
        font-size: 18px;
        letter-spacing: 0;
    }
}

// Intro Text
.poderes-instrutor-text{
    color: $green-text;
    font-family: 'Muli Bold', sans-serif;
    font-size: 18px;
    line-height: 25px;
}

// Card de Quantidade de Poderes
.poderes-instrutor-quantity{
    background-color: $blue-bg;
    color: $white-text;

    .title{
        font-family: 'Muli Bold', sans-serif;
        font-size: 18px;
        margin: 0 0 15px;
    }

    .poderes-details{
        color: $orange-text;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
    }
}

// Participants List
.poderes-instrutor-participants{
    margin: 20px 0;

    .participants-header{
        display: block;

        span{
            color: $orange-text;
            display: inline-block;
            font-family: 'Muli', sans-serif;
            font-size: 18px;
            width: 50%;

            &:first-child{
                text-align: left;
            }
        }
    }

    .participants-list-item{
        align-items: center;
        border-bottom: 1px solid rgba(112, 112, 112, .5);
        display: flex;
        justify-content: space-between;
        padding: 15px 0;

        &:last-child{
            border: none;
            padding-bottom: 0;
        }

        .participants-name{
            color: $link-text;
            font-family: 'Muli', sans-serif;
            font-size: 16px;
            text-align: left;
        }

        // Select
        .participants-select-arrow{
            cursor: pointer;
            position: relative;
            width: 50%;

            &:after{
                content: '';
                left: 90%;
                padding: 10px 10px 0 0;
                pointer-events: none;
                position: absolute;
                top: 4px;
                transform: rotate(45deg);
                width: 12px;
                border: solid #2699FB;
                border-width: 0 3px 3px 0;
            }

            .participants-select{
                appearance: none;
                color: $input-text;
                font-family: 'Muli Light', sans-serif;
                font-size: 13px;
                outline: none;
                width: 100%;
            }
        }
    }
}

// Button
.poderes-instrutor-finish{
    color: $white-text;
    background-color: $green-bg;
}

  .poderes-info-turma{
    border-radius: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    display: block;
    font-family: 'Muli Bold', sans-serif;
    font-size: 15px;
    padding: 10px 0;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    color: $green-title;
    background-color: $white-bg;
    border: 2px solid $green-title;
    outline-style: none;
  }
