@import '../../../../assets/styles/abstracts/variables';

// Main
.presenca-main{
    padding: 70px 30px 30px;
    position: relative;

    &:after{
        background: url('../../../../assets/images/instrutor/turma-icon.png');
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 50%;
        content: '';
        height: 110px;
        left: 50%;
        position: absolute;
        top: -55px;
        transform: translateX(-50%);
        width: 110px;
    }
}

// Nome da turma
.presenca-class-name{
    border-bottom: 1px solid rgba(112, 112, 112, .35);
    color: $blue-title;
    font-family: 'DK Aderyn', sans-serif;
    font-size: 26px;
    letter-spacing: 1.3px;
    line-height: 25px;
    margin: 0;
    padding-bottom: 10px;

    span{
        color: $link-text;
        display: block;
        font-family: 'Muli Light', sans-serif;
        font-size: 18px;
        letter-spacing: 0;
    }
}

// Qtd. Participantes
.presenca-count{
    color: $link-text;
    font-family: 'Muli Bold', sans-serif;
    font-size: 18px;
}

// Participants List
.presenca-list{
    margin: 20px 0;
}

.presenca-list-item{
    align-items: center;
    border-bottom: 1px solid rgba(112, 112, 112, .5);
    display: flex;
    justify-content: space-between;
    padding: 10px 0;

    &:last-child{
        border: none;
        padding-bottom: 0;
    }

    span{
        text-align: left;
        width: calc(100% - 140px);
    }
}

.presenca-list-header{
    display: block;

    span{
        color: $orange-text;
        display: inline-block;
        font-family: 'Muli', sans-serif;
        font-size: 18px;
        width: 50%;

        &:first-child{
            text-align: left;
        }
    }
}

// Input Radio
.presenca-input-radio {
	box-sizing: border-box;
	display: inline-block;
    justify-content: space-between;
    width: 140px;

	input {
        height: 0;
        left: -9999px;
        position: absolute;
        width: 0;
    }

	input + label {
        background-color: #FFF;
        border: 1px solid #3E68B2;
        border-radius: 5px;
        box-sizing: border-box;
        color: $blue-text;
        cursor: pointer;
        display: inline-block;
        font-family: 'Muli', sans-serif;
        font-size: 12px;
        margin: 0;
        padding: 2px 0;
        position: relative;
        text-align: center;
        width: 65px;

        &:last-child{
            margin-left: 10px;
        }
    }

	input:checked + label {
		color: #FFF;
        z-index: 1;

        &.falta{
            background-color: $red-button;
            border-color: $red-button;
        }

        &.presenca{
            background-color: $green-button;
            border-color: $green-button;
        }
	}
}

// Save Button
.presenca-button-save{
    background-color: $green-bg;
    color: $white-text;
}