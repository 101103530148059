@import '../../../../assets/styles/abstracts/variables';

// Main
.game-list-main{
    min-height: calc(100vh - 200px);
    padding: 70px 30px 30px;
    position: relative;

    &:after{
        background: url('../../../../assets/images/participante/icon-jogos.png');
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 50%;
        content: '';
        height: 110px;
        left: 50%;
        position: absolute;
        top: -55px;
        transform: translateX(-50%);
        width: 110px;
    }
}

// Lista de Jogos
.game-list{
    align-items: center;
    background: $blue-bg;
    border-radius: 7px;
    display: flex;
    height: 64px;
    margin-bottom: 15px;
    width: 100%;

    &.concluido{
        background: $green-bg;
    }

    @media(max-width: 330px){
        height: 90px;
    }
}

// Jogos - Image
.game-list-image{
    display: inline-block;
    height: 64px;
    position: relative;
    width: 86px;

    @media(max-width: 330px){
        height: 90px;
    }

    &.concluido{
        border-radius: 7px;

        &:before{
            border: 2px solid $green-bg;
        }
    }

    &:before{
        background: #E9F3FC;
        border: 2px solid $blue-bg;
        border-radius: 7px 0 0 7px;
        content: '';
        height: 64px;
        left: 0;
        position: absolute;
        width: 86px;

        @media(max-width: 330px){
            height: 90px;
        }
    }

    svg{
        height: 100%;
        padding: 12px 10px;
        position: relative;
        width: 100%;
    }
}

.game-list-divider{
    background: #E9F3FC;
    display: inline-flex;
    height: 22px;
    margin: 21px 13px;
    width: 1px;

    @media(max-width: 330px){
        height: 50px;
    }
}

// Trilhas - Textos
.game-list-content{
    display: block;
    padding-right: 10px;
    width: calc(100% - 138px);
}

.game-list-content-power,
.game-list-content-status{
    color: $white-text;
    font-size: 12px;
    text-align: center;
}

.game-list-content-power{
    font-family: 'Muli Bold', sans-serif;
    letter-spacing: 0.5px;
    line-height: 16px;
    margin: 0;
    text-transform: uppercase;
}

.game-list-content-status{
    font-family: 'Muli', sans-serif;
    letter-spacing: 1px;
}

// Arrow
.game-list-arrow{
    border: solid #fff;
    border-width: 0 3px 3px 0;
    height: 11px;
    margin: 25px 0;
    transform: rotate(-45deg);
    width: 11px;
}