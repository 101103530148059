@import '../../../../../assets/styles/abstracts/variables';

.oficina-instrutor-modal-title{
    color: $orange-title;
    font-family: 'DK Aderyn', sans-serif;
    font-size: 26px;
    letter-spacing: 1.3px;
    margin: 20px 0;
    text-transform: uppercase;
}

.oficina-instrutor-modal-text{
    color: $poder-text;
    font-family: 'Muli', sans-serif;
    font-size: 14px;
    line-height: 20px;
}