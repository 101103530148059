@import "../../../../assets/styles/abstracts/variables";

// Main

button{
  outline-style: none;
}

.list-turma-main {
  padding: 70px 30px 30px;
  position: relative;

  &:after {
    background: url("../../../../assets/images/instrutor/turma-icon.png");
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    content: "";
    height: 110px;
    left: 50%;
    position: absolute;
    top: -55px;
    transform: translateX(-50%);
    width: 110px;
  }
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

// Intro Text
.list-turma-text {
  color: $green-text;
  font-family: "Muli Bold", sans-serif;
  font-size: 18px;
  line-height: 25px;
}

// Create Turma - button
.list-main-create-button {
  background: $blue-bg;
  color: $white-text;
}

// Button - Turma em andamento
.list-main-title {
  color: $link-text;
  font-family: "Muli Bold", sans-serif;
  font-size: 18px;
}

// Turmas Table
.list-turma-table {
  font-family: "Muli", sans-serif;
  width: 100%;

  th {
    color: $orange-title;
    font-size: 18px;

    &:first-child {
      text-align: left;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid rgba(112, 112, 112, 0.5);

      &:last-child{
        border: none;
      }
    }

  }

  td {
    color: $blue-text;
    font-size: 17px;
    text-align: left;

    @media (max-width: 340px) {
      font-size: 15px;
    }

    &:last-child {
      align-items: center;
      display: flex;
      height: 50px;
      justify-content: center;
      text-align: center;
    }

    .code {
      color: $value-text;
      display: block;
      font-size: 15px;
      // margin-bottom: 10px;
    }

    .view-button {
      background: $green-bg;
      border-radius: 30px;
      color: $white-text;
      font-family: "Muli Bold", sans-serif;
      font-size: 14px;
      padding: 5px 10px;
      text-transform: uppercase;
    }
  }
}

.turma-item-dropdown {
  display: inline-block;
  height: 25px;
  margin-left: 15px;
  position: relative;
  width: 25px;

  .menu-icon{
    position: absolute;
    width: 25px;
  }

  input {
    height: 0;
    left: -9999px;
    position: absolute;
    width: 0;
  }

  input + label {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    height: 25px;
    line-height: 140%;
    margin: 0;
    position: relative;
    text-align: center;
    width: 25px;
  }

  input + label .dropdown-list {
    background: $content-bg;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    display: none;
    padding: 10px 15px;
    position: absolute;
    right: 0;
    text-align: left;
    top: 15px;
    width: 180px;
    z-index: 9;

    li{
      border-top: 1px solid rgba(0, 0, 0, .2);
      display: block;
      padding: 5px 0;

      &:first-child{
        border: none;
      }

      &.delete{
        button{
          color: $alert-text;
          fill: $alert-text;
        }
      }

      a,
      button{
        align-items: center;
        color: $black-text;
        cursor: pointer;
        display: flex;
        font-family: 'Muli Bold', sans-serif;
        font-size: 14px;
        letter-spacing: 0.5px;
        padding-left: 0;
        text-transform: uppercase;
      }

      .list-icon{
        margin-right: 5px;
        width: 16px;
      }
    }
  }

  input:checked + label .dropdown-list {
    display: block;
  }
}

// Button - Turmas Concluídas
.list-main-turmas-button {
  border: 2px solid $blue-text;
  color: $blue-text;
  margin-top: 18px;
  padding: 8px 0;
}

.start-or-send {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-top: 10px;
  margin-bottom: 10px;
}

#start-or-send{
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;

  margin-bottom: 10px;
}

#list-sendmissionbutton {
  font-size: 0.57rem!important;

  @media (min-width: 768px) {
    font-size: 0.65rem!important;
  }

  @media (min-width: 1024px) {
    font-size: 0.66rem!important;
  }

}

.view-button {
  background: $green-bg;
  border-radius: 30px;
  color: $white-text;
  font-family: "Muli Bold", sans-serif;
  font-size: 14px!important;
  padding: 5px 10px;
  text-transform: uppercase;

  @media (max-width: 360px) {
    font-size: 12px!important;
  }
}

.sendImages-modalPresenceList {
  background-color: #87BF40!important;

  &:disabled {
      background-color: #B8B8B8!important;
  }
}