@import "../../../../assets/styles/abstracts/variables";

// Avatar Item
.avatares-item {
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  height: 70px;
  margin: 20px 20px 0 0;
  min-width: 70px;

  input {
    height: 0;
    left: -9999px;
    position: absolute;
    width: 0;
  }

  input + label {
    border: 2px solid #707070;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
  }

  input:checked + label {
    border: 2px solid $orange-bg;
    color: #fff;
    z-index: 1;
  }

  img {
    border-radius: 50%;
    height: 70px;
    object-fit: contain;
    width: 70px;
  }
}