@import '../../../assets/styles/abstracts/variables';

.login-participante-header{
    height: 27vh;
    margin-bottom: 40px;
    padding: 40px 0;

    .login-participante-header-image{
        height: 75px;
    }

    .login-participante-header-title{
        color: $page-title;
        font-family: 'DK Aderyn', sans-serif;
        font-size: 27px;
        letter-spacing: 1.5px;
        margin: 20px 0 0;
        text-transform: uppercase;
    }
}

.login-participante {
    text-align: center;
}

.login-participante-main {
    height: calc(100% - 27vh);
    padding: 30px 40px;
    width: 100%;
}

.login-participante-title {
    color: $orange-title;
    font-family: 'Muli Bold', sans-serif;
    font-size: 19px;

    span{
        color: $blue-title;
        display: block;
        font-family: 'Muli', sans-serif;
        margin-top: 5px;
    }
}

.login-participante-label {
    color: $label-text;
    display: block;
    font-family: 'Muli Bold', sans-serif;
    font-size: 18px;
    margin: 20px 0;
}

.login-participante-input {
    border-bottom: 0.5px solid #000;
	color: $input-text;
	display: block;
	font-family: 'Muli Light', sans-serif;
    font-size: 13px;
    line-height: 30px;
    margin: 0 auto;
    outline: none;
	text-align: center;
    width: 80%;
}

.login-participante-submit {
	background: $orange-button;
	color: $white-text;
    margin-top: 40px;
    outline-style: none;
}

.login-participante-cancel {
	background: $blue-button;
	color: $white-text;
    margin-top: 40px;
    outline-style: none;
}

.login-participante-back {
    color: $blue-text;
    display: block;
    font-family: 'Muli', sans-serif;
    font-size: 16px;
    margin-top: 45px;
}