@import "../../../../../assets/styles/abstracts/variables";

.missoes-instrutor-modal {
  // Modal Title
  .modal-title {
    color: $orange-title;
    font-family: "DK Lemon", sans-serif;
    font-size: 29px;
    letter-spacing: 3px;
    margin: 0;
  }

  // Participant Name
  .modal-participant-name {
    color: $value-text;
    font-family: "Muli Bold", sans-serif;
    font-size: 18px;
    margin: 0 0 10px;

    span {
      color: $blue-text;
      font-family: "Muli", sans-serif;
      font-size: 15px;
    }
  }

  // Label
  .modal-label {
    color: $label-text;
    font-family: "Muli Bold", sans-serif;
    font-size: 18px;
  }

  // Select
  .modal-select-arrow {
    border-bottom: 0.5px solid #000000;
    cursor: pointer;
    display: flex;
    position: relative;
    margin: 10px auto 25px;
    width: 85%;

    &:after {
      content: "";
      left: 95%;
      padding: 9px 0 0 0;
      pointer-events: none;
      position: absolute;
      transform: rotate(45deg);
      width: 12px;
      border: solid #2699fb;
      border-width: 0 3px 3px 0;
    }

    .modal-select {
      color: $input-text;
      font-family: "Muli", sans-serif;
      font-size: 17px;
      padding-bottom: 10px;
      text-align: center;
      appearance: none;
      position: relative;
      text-align-last: center;
      vertical-align: middle;
      width: 100%;
      outline: none;
    }
  }

  // Subtitle
  .modal-subtitle {
    color: $green-text;
    font-family: "DK Aderyn", sans-serif;
    font-size: 20px;
    letter-spacing: 1px;
  }

  // Info Text
  .modal-info-text{
    color: $alert-text;
    font-family: 'Muli Light', sans-serif;
    font-size: 18px;
    line-height: 26px;
  }

  // Card
  .modal-card {
    background: $blue-bg;
    border-radius: 9px;
    box-shadow: 0 3px 6px #00000029;
    margin-bottom: 15px;
    padding: 15px;

    input {
      display: none;
      visibility: hidden;
    }

    .modal-card-text {
      border-top: 1px solid rgba(255, 255, 255, 0.4);
      color: $white-text;
      display: none;
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 20px;
      overflow: hidden;
      padding-top: 10px;
      transition: display 1000ms ease;
    }

    input:checked ~ .modal-card-text {
      display: block;
    }
  }

  // Card - Header
  .modal-card-title {
    align-items: center;
    display: flex;
    justify-content: space-between;

    h3 {
      color: $white-text;
      display: inline-block;
      font-family: "Dk Aderyn", sans-serif;
      font-size: 22px;
      letter-spacing: 1px;
      margin: 0;
      text-align: left;
    }

    span {
      border: solid $white-bg;
      border-width: 0 3px 3px 0;
      display: inline-block;
      height: 11px;
      transform: rotate(-45deg);
      width: 11px;
    }
  }
}
