@import "../../../../../assets/styles/abstracts/variables";


.missionInstructorStatusFlag_div {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 640px) {
        justify-content: center;
    }
}

.flag-text {
    background-color: $red-bg;
    color: #ffffff;
    padding: 12px 10px 12px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 600;
    margin: 6px 0 6px 0;

    @media (max-width: 640px) {
        justify-content: center;
        padding: 8px 8px 8px 8px;
        font-size: 0.6rem;
    }
}