.button-full{
    border-radius: 30px;
    cursor: pointer;
    display: block;
    font-family: 'Muli Bold', sans-serif;
    font-size: 16px;
    padding: 10px 0;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    outline-style: none;
}