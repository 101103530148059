@import "../../../../assets/styles/abstracts/variables";

.profile-main {
  .form-group {
    &:nth-child(3) {
      margin: 25px 0;
    }
  }
}

// Label
.about-label {
  color: $blue-text;
  display: block;
  font-family: "Muli Bold", sans-serif;
  font-size: 18px;
  margin-bottom: 10px;
  text-align: left;
}

// Input e Select
.about-input,
.about-select {
  background: $white-bg;
  border-radius: 9px;
  box-shadow: 0 3px 6px #00000029;
  color: $input-text;
  font-family: "Muli Light", sans-serif;
  font-size: 13px;
  padding: 15px 20px;
  width: 100%;
}

// Select
.about-select-arrow {
  cursor: pointer;
  width: 100%;

  &:after {
    content: "";
    left: 90%;
    padding: 13px 0 0 0;
    pointer-events: none;
    position: absolute;
    top: 47px;
    transform: rotate(45deg);
    width: 16px;
    border: solid #2699fb;
    border-width: 0 3px 3px 0;
  }

  .about-select {
    appearance: none;
    outline: none;
  }
}
