@import '../../../../assets/styles/abstracts/variables';

// Main
.certificado-treinamento-main{
    padding: 70px 30px 30px;
    position: relative;

    &:after{
        background: url('../../../../assets/images/instrutor/icon-certificado.png');
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 50%;
        content: '';
        height: 110px;
        left: 50%;
        position: absolute;
        top: -55px;
        transform: translateX(-50%);
        width: 110px;
    }
}

// Intro Text
.certificado-treinamento-main-intro{
    color: $blue-text;
    font-family: 'Muli Bold', sans-serif;
    font-size: 18px;
    line-height: 22px;
    padding: 0 10px;
}

// Card
.certificado-treinamento-card{
    border: 2px solid #87BF40;
    padding: 30px 20px;

    .logo-image{
        padding: 0 30px;
    }

    .certificado-title{
        color: $orange-title;
        font-family: 'Muli Bold', sans-serif;
        font-size: 18px;
        line-height: 22px;
        margin: 20px 0;
    }

    .certificado-text{
        color: $black-text;
        font-family: 'Roboto', sans-serif;
        font-size: 17px;
        line-height: 22px;

        .intro{
            display: block;
            font-size: 19px;
        }

        .name{
            color: $alert-text;
            display: block;
            font-family: 'Muli Bold', sans-serif;
            font-size: 21px;
            letter-spacing: 0.5px;
            margin: 15px 0;
            text-transform: uppercase;
        }
    }

    .certificado-date{
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
    }

    .assinatura-image{
        display: block;
        margin: 0 auto;
        width: 100px;
    }

    .certificado-legenda{
        color: $black-text;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 20px;
        margin-bottom: 0;
    }
}

// Botões
.certificado-treinamento-button{
    display: block;

    &.filled{
        background: $orange-bg;
        border: 2px solid #F69522;
        color: $white-text;
        margin: 20px 0;
    }

    &.outline{
        border: 2px solid #F69522;

        a{
            color: $orange-button;
        }
    }
}