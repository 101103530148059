@import "../../../../assets/styles/abstracts/variables";

// Main
.turma-main {
  min-height: calc(100vh - 200px);
  padding: 70px 30px 30px;
  position: relative;

  &:after {
    background: url("../../../../assets/images/instrutor/turma-icon.png");
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    content: "";
    height: 110px;
    left: 50%;
    position: absolute;
    top: -55px;
    transform: translateX(-50%);
    width: 110px;
  }
}

// Nome da turma
.turma-class-name {
  border-bottom: 1px solid rgba(112, 112, 112, 0.35);
  color: $blue-title;
  font-family: "DK Aderyn", sans-serif;
  font-size: 26px;
  letter-spacing: 1.3px;
  line-height: 25px;
  margin: 0;
  padding-bottom: 10px;

  span {
    color: $link-text;
    display: block;
    font-family: "Muli Light", sans-serif;
    font-size: 18px;
    letter-spacing: 0;
  }
}

// List
.turma-oficina-content {
  padding: 5px 15px 15px;

  .participant-info,
  .attendance,
  .star {
    display: inline-block;
  }

  .oficina-title {
    color: $link-text;
    display: block;
    font-family: "Muli Light", sans-serif;
    font-size: 18px;
    letter-spacing: 0;
    font-weight: bold;
  }

  .participant-info {
    width: 70%;

    span {
      color: $value-text;
      display: block;
      font-family: "Muli", sans-serif;
      font-size: 15px;
      text-align: left;

      &:first-child {
        color: $blue-text;
      }
    }
  }

  .attendance {
    width: 15%;

    span {
      font-size: 20px;
      font-family: "Muli", sans-serif;

      &.attend {
        color: green;
      }
      &.absence {
        color: red;
      }
    }
  }

  .star {
    width: 15%;

    .icon {
      display: block;
      margin: 0 auto;
      width: 40px;

      &.incomplete {
        fill: #fff;
        stroke: $disabled-button;
        stroke-dasharray: 2.5;
      }

      &.completed {
        fill: $green-bg;
      }

      &.later {
        fill: $orange-bg;
      }
    }
  }
  .participant-info span::nth-last-child(2) {
    display: block;
    margin: 10px auto 0;
    color: #87bf40;
    text-align: center;
  }
}

.consult-power-info {
  text-decoration: underline $blue-bg;
  cursor: pointer;
}

// .consult-power-info::after{
//   background: $blue-bg;
//   margin-left: 5px;
//   border-radius: 50%;
//   content: "i";
//   color: $white-text;
//   font-family: "Muli", sans-serif;
//   font-size: 10px;
//   text-transform: lowercase;
//   padding: 1px 5px 1px 5px;
//   width: 20px;
// }

// List
.turma-oficina-list {
  margin: 0;
}

// List Item
.turma-oficina-list-item {
  align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
  display: flex;
  margin: 10px 0;
  padding: 5px 0;

  &:last-child {
    border: none;
    margin-bottom: 0;
  }
}

// Carousel
.oficina-carousel-menu {
  display: flex;
  justify-content: space-around;
  list-style: none;
}

.oficina-carousel-menu-item {
  color: $blue-text;
  font-family: "Muli Light", sans-serif;
  font-size: 16px;
  cursor: pointer;

  &.active {
    border-bottom: 2px solid #3e68b2;
    font-weight: bold;
  }
}

.consult-info-icon {
  fill: $blue-bg;
  margin-left: 10px;
  width: 20px;
  text-align: right;
}

.filter-turma {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 1.3rem;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5rem;
  box-shadow: 0 3px 6px #00000029;

  h4 {
    color: #8a73a5;
    display: block;
    font-family: "Muli Bold", sans-serif;
    font-size: 18px;
    margin: 0.725rem 0 0 0;
  }
}

.filter-turma-select-arrow {
  border-bottom: 0.5px solid #000000;
  cursor: pointer !important;
  display: flex;
  margin: 15px auto;
  width: 40%;

  @media screen and (max-width: 500px) {
    width: 80%;
  }

  &:after {
    content: "";
    left: 66%;
    padding: 9px 0 0 0;
    pointer-events: none;
    position: absolute;
    transform: rotate(45deg);
    width: 12px;
    border: solid #2699fb;
    border-width: 0 3px 3px 0;
    cursor: pointer !important;

    &:hover {
      cursor: pointer !important;
    }

    @media screen and (max-width: 500px) {
      left: 75%;
    }
  }

  .filter-turma-select {
    color: $input-text;
    font-family: "Muli Light", sans-serif;
    font-size: 13px;
    padding-bottom: 10px;
    text-align: center;
    appearance: none;
    position: relative;
    text-align-last: center;
    vertical-align: middle;
    width: 100%;
    outline: none;
  }
}