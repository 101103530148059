@import '../../assets/styles/abstracts/variables';

.welcome{
    .header-user{
        display: none;
    }
}

// Header
.page-title-border{
    &.onboarding{
        width: 240px
    }
}

// Main
.welcome-main{
    margin-top: 120px;
    position: relative;
    padding: 90px 30px 30px;

    &:after{
        background: $orange-bg;
        border-radius: 50%;
        content: '' ;
        height: 110px;
        left: 50%;
        letter-spacing: 4.5px;
        padding: 30px 17px;
        position: absolute;
        top: -55px;
        transform: translateX(-50%);
        width: 110px;
    }

    @media(max-width: 380px){
        padding: 55px 15px 20px;
    }
}

// Circle text
.welcome-main-about{
    color: $white-text;
    font-family: 'DK Lemon', sans-serif;
    font-size: 34px;
    left: 50%;
    position: absolute;
    top: -25px;
    transform: translateX(-50%);
    z-index: 9;
}

.welcome-image{
    left: 50%;
    position: absolute;
    top: -100px;
    transform: translateX(-50%);
    width: 374px;
}

.welcome-card{
    position: relative;
}

// Title
.welcome-card-title{
    color: $orange-title;
    font-family: 'Muli Bold', sans-serif;
    font-size: 19px;
    margin-top: 0;
}

// Text
.welcome-card-text{
    font-family: 'Roboto Light', sans-serif;
    font-size: 19px;
    letter-spacing: 0.5px;
}

// Botão
.welcome-button{
    background: $orange-bg;
    color: $white-text;
    border-radius: 30px;
    display: block;
    font-family: 'Muli Bold', sans-serif;
    font-size: 16px;
    margin-top: 28px;
    padding: 10px 0;
    text-transform: uppercase;
    width: 100%;
}