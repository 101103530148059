@import '../../assets/styles/abstracts/variables';

// Header
.login{
    text-align: center;

    .image-language{
        border-radius: 10px;
        box-shadow: 2px 2px 10px #A1A1A1;
        margin: 0 5px;
    }
}

.login-header{
    height: 27vh;
    margin-bottom: 40px;
    padding: 40px 0;

    .login-header-image{
        height: 75px;
    }

    .login-header-title{
        color: $page-title;
        font-family: 'DK Aderyn', sans-serif;
        font-size: 27px;
        letter-spacing: 1.5px;
        margin: 20px 0 0;
        text-transform: uppercase;
    }
}

// Main
.login-main{
	height: 100%;
	padding: 30px 50px 0;
	width: 100%;
}

// Código de turma
.login-label{
	color: $label-text;
	display: block;
	font-family: 'Muli Bold', sans-serif;
	font-size: 18px;
	margin-bottom: 18px;
}

.login-input{
	border-bottom: 0.5px solid #000;
	color: $input-text;
	display: block;
	font-family: 'Muli Light', sans-serif;
	font-size: 13px;
	line-height: 30px;
	margin: 0 auto;
	outline: none;
	text-align: center;
	width: 80%;
}

.login-submit{
	background: $orange-button;
	color: $white-text;
	margin-top: 20px;
}

// Instrutor
.login-instrutor-link{
	background: $blue-button;
	color: $white-text;
	margin-top: 20px;
}

// Image
.login-box-image{
	height: 300px;
	position: relative;
}

.login-image{
	bottom: 0;
	height: 250px;
	left: 50%;
	margin-top: 20px;
	position: absolute;
	transform: translateX(-50%);
	// width: 30vmin;

	@media(min-width: 1024px){
		width: 20vmin;
	}

	// @media(max-height: 639px){
	// 	display: none;
	// }
}