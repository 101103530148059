@import '../../../../assets/styles/abstracts/variables';

// Common Style
// first text
.list-oficinas-campanha-text{
    color: $blue-title;
    font-family: 'Muli Bold', sans-serif;
    font-size: 18px;
    margin: 0;
}

// Main
.list-oficinas-campanha-main{
    min-height: calc(100vh - 200px);
    padding: 70px 30px 30px;
    position: relative;

    &:after{
        background: url('../../../../assets/images/oficinas/icon-oficinas.png');
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 50%;
        content: '';
        height: 110px;
        left: 50%;
        position: absolute;
        top: -55px;
        transform: translateX(-50%);
        width: 110px;
    }

    // Title and status
    .content-title,
    .content-status{
        color: $white-text;
        display: block;
        font-size: 12px;
        text-align: center;
    }

    .content-title{
        font-family: 'Muli Bold', sans-serif;
        letter-spacing: 0.5px;
        line-height: 16px;
        margin: 0;
        text-transform: uppercase;
    }

    .content-status{
        font-family: 'Muli', sans-serif;
        letter-spacing: 1px;
    }
}

.list-oficinas-campanha-card{
    align-items: center;
    background: $blue-bg;
    border-radius: 7px;
    display: flex;
    margin: 20px 0;
    width: 100%;
}

// Oficina - Image
.list-oficinas-campanha-image{
    display: inline-block;
    height: auto;
    object-fit: contain;
    position: relative;

    &.oficina{
        width: 85px;
    }

    &.campanha{
        margin-right: 10px;
        width: 100px;

        @media(max-width: 330px){
            display: none;
        }
    }
}

// Oficina Styles
// Oficina - Divider
.list-oficinas-divider{
    background: #E9F3FC;
    display: inline-flex;
    height: 22px;
    margin: 21px 13px;
    width: 1px;

    @media(max-width: 330px){
        height: 50px;
    }
}

// Oficina - Content
.list-oficinas-content{
    display: block;
    padding-right: 10px;
    width: calc(100% - 135px);
}

// Oficina - Arrow
.list-oficinas-arrow{
    border: solid #fff;
    border-width: 0 3px 3px 0;
    height: 11px;
    margin: 25px 0;
    transform: rotate(-45deg);
    width: 11px;
}

// Campanha Styles
// Campanha - Content
.list-campanha-content{
    display: block;
    padding: 10px 0;
    width: 100%;
}

// Campanha - Divider
.list-campanha-divider{
    background: rgba(233, 243, 252, .5);
    display: block;
    height: 0.5px;
    margin: 7px auto;
    width: 80%;
}

// Campanha - Progresso
.campanha-participante-timeline-progress{
    svg{
        fill: $white-bg;
        width: 16px;

        @media(max-width: 379px) and (min-width: 331px){
            width: 11px;
        }
    }

    div{
        align-items: center;
        display: inline-flex;
        margin: 0 8px;
    }

    .timeline-list-item{
        background: $white-bg;
        border: 1px solid #F69522;
        border-radius: 50%;
        color: $black-text;
        display: inline-block;
        font-family: 'Muli Bold', sans-serif;
        font-size: 11px;
        height: 18px;
        width: 18px;

        &.active{
            background: $orange-button;
            border-color: $white-bg;
            color: $white-text;
        }
    }

    .timeline-list-item-divider{
        background: $orange-bg;
        height: 2px;
        margin: 0 3px;
        width: 10px;

        @media(max-width: 379px) and (min-width: 331px){
            width: 2px;
        }
    }
}
