@import '../../../assets/styles/abstracts/variables';

// Main
.list-missao-main{
    min-height: calc(100vh - 200px);
    padding: 70px 30px 30px;
    position: relative;

    &:after{
        background: url('../../../assets/images/participante/icon-estrelas.png');
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 50%;
        content: '';
        height: 110px;
        left: 50%;
        position: absolute;
        top: -55px;
        transform: translateX(-50%);
        width: 110px;
    }
}

// Text
.list-missao-text{
    color: $blue-text;
    font-family: 'Muli Bold', sans-serif;
    font-size: 18px;
    line-height: 26px;
}

// Missoes List
.list-missao-trilha{
    align-items: center;
    background: $blue-bg;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    height: 64px;
    margin-bottom: 15px;
    position: relative;
    width: 100%;

    &.finished{
        background: $green-bg;
    }

    @media(max-width: 330px){
        height: 90px;
    }
}

// Content
.list-missao-trilha-content{
    display: block;
    width: 100%;
}

.list-missao-trilha-content-title,
.list-missao-trilha-content-power{
    color: $white-text;
    text-align: center;
}

.list-missao-trilha-content-title{
    font-family: 'Muli Bold', sans-serif;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 16px;
    margin: 0;
    text-transform: uppercase;
}

.list-missao-trilha-content-power{
    font-family: 'Muli', sans-serif;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: capitalize;
}

// Arrow
.list-missao-trilha-arrow{
    border: solid #fff;
    border-width: 0 3px 3px 0;
    height: 11px;
    position: absolute;
    right: 15px;
    transform: rotate(-45deg);
    width: 11px;
}

// Subtitle
.list-missao-subtitle{
    color: $green-title;
    font-family: 'Muli Bold', sans-serif;
    font-size: 18px;
    text-transform: uppercase;
}