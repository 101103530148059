// Background
$blue-bg: #3E68B2;
$content-bg: #FFF3E7;
$green-bg: #87BF40;
$yellow-bg: #c7bc1e;
$orange-bg: #F69522;
$purple-bg: #D978AE;
$white-bg: #ffffff;
$red-bg: #ed5a5a;

// Button
$blue-button: #3E68B2;
$disabled-button: #9D9D9D;
$gray-button: #9D9D9D;
$green-button: #87BF40;
$red-button: #E81E47;
$orange-button: #F69522;

// Elements
$title-border: rgba(246, 149, 34, .5);

// Title
$page-title: #707070;
$blue-title: #3E68B2;
$green-title: #87BF40;
$orange-title: #F69522;

// Text
$alert-text: #E81E47;
$black-text: #000000;
$blue-text: #3E68B2;
$green-text: #87BF40;
$input-text: #303030;
$label-text: #8a73a5;
$link-text: #303030;
$orange-text: #F69522;
$poder-text: #707070;
$progresso-item: #000000DB;
$red-text: #F97171;
$value-text: #312F2F;
$white-text: #ffffff;