/*! sanitize.css v4.1.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Display definitions
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 * 2. Add the correct display in IE.
 */
 article,
 aside,
 details, /* 1 */
 figcaption,
 figure,
 footer,
 header,
 main, /* 2 */
 menu,
 nav,
 section,
 summary { /* 1 */
   display: block;
 }
 /**
  * Add the correct display in IE 9-.
  */
 audio,
 canvas,
 progress,
 video {
   display: inline-block;
 }
 /**
  * Add the correct display in iOS 4-7.
  */
 audio:not([controls]) {
   display: none;
   height: 0;
 }
 /**
  * Add the correct display in IE 10-.
  * 1. Add the correct display in IE.
  */
 template, /* 1 */
 [hidden] {
   display: none;
 }
 /* Elements of HTML (https://www.w3.org/TR/html5/semantics.html)
    ========================================================================== */
 /**
  * 1. Remove repeating backgrounds in all browsers (opinionated).
  * 2. Add box sizing inheritence in all browsers (opinionated).
  */
 *,
 ::before,
 ::after {
   background-repeat: no-repeat; /* 1 */
   box-sizing: inherit; /* 2 */
 }
 /**
  * 1. Add text decoration inheritance in all browsers (opinionated).
  * 2. Add vertical alignment inheritence in all browsers (opinionated).
  */
 ::before,
 ::after {
   text-decoration: inherit; /* 1 */
   vertical-align: inherit; /* 2 */
 }
 /**
  * 1. Add border box sizing in all browsers (opinionated).
  * 2. Add the default cursor in all browsers (opinionated).
  * 3. Add a flattened line height in all browsers (opinionated).
  * 4. Prevent font size adjustments after orientation changes in IE and iOS.
  * 5. Prevent bugs of antialising.
  */
 html {
   box-sizing: border-box; /* 1 */
   cursor: default; /* 2 */
   font-family: sans-serif; /* 3 */
   line-height: 1.5; /* 3 */
   -ms-text-size-adjust: 100%; /* 4 */
   -webkit-text-size-adjust: 100%; /* 4 */
   -webkit-font-smoothing: antialiased; /* 5 */
 }
 /* Sections (https://www.w3.org/TR/html5/sections.html)
    ========================================================================== */
 /**
  * Remove the margin in all browsers (opinionated).
  */
 body {
   margin: 0;
   overflow-x: hidden;
 }
 /**
  * Correct the font sizes and margins on `h1` elements within
  * `section` and `article` contexts in Chrome, Firefox, and Safari.
  */
 h1 {
   font-size: 2em;
   margin: .67em 0;
 }
 /* Grouping content (https://www.w3.org/TR/html5/grouping-content.html)
    ========================================================================== */
 /**
  * 1. Correct font sizing inheritance and scaling in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  */
 code,
 kbd,
 pre,
 samp {
   font-family: monospace, monospace; /* 1 */
   font-size: 1em; /* 2 */
 }
 /**
  * 1. Correct the height in Firefox.
  * 2. Add visible overflow in Edge and IE.
  */
 hr {
   height: 0; /* 1 */
   overflow: visible; /* 2 */
 }
 /**
  * Remove the list style on navigation lists in all browsers (opinionated).
  */
 ol,
 ul {
   list-style: none;
   padding: 0;
 }
 /* Text-level semantics
    ========================================================================== */
 /**
  * 1. Add a bordered underline effect in all browsers.
  * 2. Remove text decoration in Firefox 40+.
  */
 abbr[title] {
   border-bottom: 1px dotted; /* 1 */
   text-decoration: none; /* 2 */
 }
 /**
  * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
  */
 b,
 strong {
   font-weight: inherit;
 }
 /**
  * Add the correct font weight in Chrome, Edge, and Safari.
  */
 b,
 strong {
   font-weight: bolder;
 }
 /**
  * Add the correct font style in Android 4.3-.
  */
 dfn {
   font-style: italic;
 }
 /**
  * Add the correct colors in IE 9-.
  */
 mark {
   background-color: #ffff00;
   color: #000000;
 }
 /**
  * Add the correct vertical alignment in Chrome, Firefox, and Opera.
  */
 progress {
   vertical-align: baseline;
 }
 /**
  * Correct the font size in all browsers.
  */
 small {
   font-size: 83.3333%;
 }
 /**
  * Change the positioning on superscript and subscript elements
  * in all browsers (opinionated).
  * 1. Correct the font size in all browsers.
  */
 sub,
 sup {
   font-size: 83.3333%; /* 1 */
   line-height: 0;
   position: relative;
   vertical-align: baseline;
 }
 sub {
   bottom: -.25em;
 }
 sup {
   top: -.5em;
 }
 /*
  * Remove the text shadow on text selections (opinionated).
  * 1. Restore the coloring undone by defining the text shadow (opinionated).
  */
 ::-moz-selection {
   background-color: #000000; /* 1 */
   color: #fff; /* 1 */
   text-shadow: none;
 }
 ::selection {
   background-color: #000000; /* 1 */
   color: #fff; /* 1 */
   text-shadow: none;
 }
 /* Embedded content (https://www.w3.org/TR/html5/embedded-content-0.html)
    ========================================================================== */
 /*
  * Change the alignment on media elements in all browers (opinionated).
  */
 audio,
 canvas,
 iframe,
 img,
 svg,
 video {
   vertical-align: middle;
 }
 /**
  * Remove the border on images inside links in IE 10-.
  */
 img {
   border-style: none;
   max-width: 100%;
 }
 /**
  * Hide the overflow in IE.
  */
 svg:not(:root) {
   overflow: hidden;
 }
 /* Links (https://www.w3.org/TR/html5/links.html#links)
    ========================================================================== */
 /**
  * 1. Remove the gray background on active links in IE 10.
  * 2. Remove the gaps in underlines in iOS 8+ and Safari 8+.
  */
 a {
   background-color: transparent; /* 1 */
   -webkit-text-decoration-skip: objects; /* 2 */
   text-decoration: none;
 }
 /**
  * Remove the outline when hovering in all browsers (opinionated).
  */
 a:hover {
   outline-width: 0;
 }
 /* Tabular data (https://www.w3.org/TR/html5/tabular-data.html)
    ========================================================================== */
 /*
  * Remove border spacing in all browsers (opinionated).
  */
 table {
   border-collapse: collapse;
   border-spacing: 0;
 }
 /* transform-style:  (https://www.w3.org/TR/html5/forms.html)
    ========================================================================== */
 /**
  * 1. Remove the default styling in all browsers (opinionated).
  * 3. Remove the margin in Firefox and Safari.
  */
 button,
 input,
 select,
 textarea {
   background-color: transparent; /* 1 */
   border-style: none; /* 1 */
   color: inherit; /* 1 */
   font-size: 1em; /* 1 */
   margin: 0; /* 3 */
 }
 /**
  * Correct the overflow in IE.
  * 1. Correct the overflow in Edge.
  */
 button,
 input { /* 1 */
   overflow: visible;
 }
 /**
  * Remove the inheritance in Edge, Firefox, and IE.
  * 1. Remove the inheritance in Firefox.
  */
 button,
 select { /* 1 */
   text-transform: none;
 }
 /**
  * 1. Prevent the WebKit bug where (2) destroys native `audio` and `video`
  *    controls in Android 4.
  * 2. Correct the inability to style clickable types in iOS and Safari.
  */
 button,
 html [type="button"], /* 1 */
 [type="reset"],
 [type="submit"] {
   -webkit-appearance: button; /* 2 */
 }
 /**
  * Remove the inner border and padding in Firefox.
  */
 ::-moz-focus-inner {
   border-style: none;
   padding: 0;
 }
 /**
  * Correct the focus styles unset by the previous rule.
  */
 :-moz-focusring {
   outline: 1px dotted ButtonText;
 }
 /**
  * Correct the border, margin, and padding in all browsers.
  */
 fieldset {
   border: 1px solid #c0c0c0;
   margin: 0 2px;
   padding: .35em .625em .75em;
 }
 /**
  * 1. Correct the text wrapping in Edge and IE.
  * 2. Remove the padding so developers are not caught out when they zero out
  *    `fieldset` elements in all browsers.
  */
 legend {
   display: table; /* 1 */
   max-width: 100%; /* 1 */
   padding: 0; /* 2 */
   white-space: normal; /* 1 */
 }
 /**
  * 1. Remove the vertical scrollbar in IE.
  * 2. Change the resize direction on textareas in all browsers (opinionated).
  */
 textarea {
   overflow: auto; /* 1 */
   resize: vertical; /* 2 */
 }
 /**
  * Remove the padding in IE 10-.
  */
 [type="checkbox"],
 [type="radio"] {
   padding: 0;
 }
 /**
  * Correct the cursor style on increment and decrement buttons in Chrome.
  */
 ::-webkit-inner-spin-button,
 ::-webkit-outer-spin-button {
   height: auto;
 }
 /**
  * 1. Correct the odd appearance in Chrome and Safari.
  * 2. Correct the outline style in Safari.
  */
 [type="search"] {
   -webkit-appearance: textfield; /* 1 */
   outline-offset: -2px; /* 2 */
 }
 /**
  * Remove the inner padding and cancel buttons in Chrome and Safari for OS X.
  */
 ::-webkit-search-cancel-button,
 ::-webkit-search-decoration {
   -webkit-appearance: none;
 }
 /**
  * Correct the text style on placeholders in Chrome, Edge, and Safari.
  */
 ::-webkit-input-placeholder {
   color: inherit;
   opacity: .54;
 }
 /**
  * 1. Correct the inability to style clickable types in iOS and Safari.
  * 2. Change font properties to `inherit` in Safari.
  */
 ::-webkit-file-upload-button {
   -webkit-appearance: button; /* 1 */
   font: inherit; /* 2 */
 }
 /* WAI-ARIA (https://www.w3.org/TR/html5/dom.html#wai-aria)
    ========================================================================== */
 /**
  * Change the cursor on busy elements (opinionated).
  */
 [aria-busy="true"] {
   cursor: progress;
 }
 /*
  * Change the cursor on control elements (opinionated).
  */
 [aria-controls] {
   cursor: pointer;
 }
 /*
  * Change the cursor on disabled, not-editable, or otherwise
  * inoperable elements (opinionated).
  */
 [aria-disabled] {
   cursor: default;
 }
 /* User interaction (https://www.w3.org/TR/html5/editing.html)
    ========================================================================== */
 /*
  * Remove the tapping delay on clickable elements (opinionated).
  * 1. Remove the tapping delay in IE 10.
  */
 a,
 area,
 button,
 input,
 label,
 select,
 textarea,
 [tabindex] {
   -ms-touch-action: manipulation; /* 1 */
   touch-action: manipulation;
 }
 /*
  * Change the display on visually hidden accessible elements (opinionated).
  */
 [hidden][aria-hidden="false"] {
   clip: rect(0, 0, 0, 0);
   display: inherit;
   position: absolute;
 }
 [hidden][aria-hidden="false"]:focus {
   clip: auto;
 }

 /* Fixes date input visual bug on Safari  */
 @supports (font: -apple-system-body) and (-webkit-appearance: none) {
  input[type="date"]::before {
    content: 'dd/mm/aaaa';
    opacity: 0.55;
  }
  
  input[type="date"]:is(:user-valid)::before {
    content: none;
  }
 }