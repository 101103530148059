@import '../../../../assets/styles/abstracts/variables';

.cadastro-instrutor-adicionais-text{
    color: $orange-text;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding: 0 30px;
    text-align: center;
}

// Input - Radio
.cadastro-instrutor-input-radio{
    &.declaracao-responsabilidade{
        justify-content: center;
        margin-bottom: 20px;
    }
}

// Input Checkbox
.form-group-checkbox{
    margin-bottom: 14px;
    padding: 0 25px;
    text-align: left;

    input[type="checkbox"] {
        cursor: pointer;
        display: none;
    }

    input[type="text"]{
        border-bottom: 0.5px solid #000000;
        outline: none;
    }

    label {
        color: $blue-text;
        cursor: pointer;
        position: relative;

        &:before{
            content:'';
            cursor: pointer;
            background-color: transparent;
            border: 2px solid #F69522;
            border-radius: 9px;
            display: inline-block;
            height: 30px;
            margin-right: 8px;
            padding: 10px;
            position: relative;
            vertical-align: middle;
            width: 30px;
        }
    }
}

.form-group-checkbox input:checked + label:after {
    background: $blue-button;
    border-radius: 50%;
    content: '';
    display: block;
    height: 10px;
    left: 10px;
    position: absolute;
    top: 5px;
    transform: rotate(45deg);
    width: 10px;
}