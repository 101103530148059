@import '../../../assets/styles/abstracts/variables';

.home-participante{
    .header-arrow{
        display: none;
    }
}

.home-participante-main{
    background: none;
    height: 100%;
    padding-top: 70px;
}

.home-participante-header{
    height: 45vh;
    position: relative;

    @media(max-width: 360px){
        height: 50vh;
    }
}

// Welcome
.home-participante-welcome{
    color: $black-text;
    display: inline-block;
    font-family: 'Muli Light', sans-serif;
    font-size: 28px;
    left: 35px;
    position: absolute;
    text-align: left;

    @media(max-width: 360px){
        font-size: 26px;
    }

    .participante-name{
        display: block;
        font-family: 'Muli Semibold';
    }
}

// Minhas Conquistas
.home-participante-conquistas{
    display: inline-block;
    position: absolute;
    right: 20px;

    @media(max-width: 360px){
        right: 0;
    }

    .participante-conquista-title{
        font-family: 'DK Aderyn', sans-serif;
        font-size: 19px;
        margin-bottom: 0;
        text-transform: uppercase;
    }

    .participante-quadro{
        align-items: center;
        background-image: url('../../../assets/images/participante/minhas-conquistas.png');
        background-repeat: no-repeat;
        background-size: contain;
        display: flex;
        font-family: 'Muli', sans-serif;
        font-size: 16px;
        height: 220px;
        justify-content: center;
        width: 170px;

        @media(max-width: 360px){
            height: 200px;
            width: 150px;
        }

        .participante-titles{
            color: $black-text;
            font-size: 16px;
            margin: 10px 0;

            &:nth-child(1){
                margin-top: 0;
            }

            @media(max-width: 360px){
                font-size: 14px;
            }
        }

        .participante-quadro-icon{
            margin-right: 10px;
            width: 40px;

            @media(max-width: 360px){
                width: 30px;
            }
        }

        .participante-quadro-count{
            border-left: 0.5px solid #707070;
            color: $black-text;
            padding-left: 10px;
        }
    }
}

// Content
.home-partipante-content{
    background-color: $content-bg;
	border-top-left-radius: 100% 10%;
    border-top-right-radius: 100% 10%;
    padding: 90px 30px 30px;
    position: relative;
    text-align: center;

    @media(max-width: 360px){
        padding: 70px 20px 30px;
    }

    img{
        position: absolute;
        left: 20px;
        top: -160px;
        width: 180px;

        @media(max-width: 360px){
            top: -130px;
            width: 150px;
        }
    }
}

// Módulos
.home-participante-modulos{
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px;
    display: inline-flex;
    height: 130px;
    justify-content: center;
    vertical-align: middle;
    width: 48%;

    &.oficina{
        background-image: url('../../../assets/images/participante/bg-oficina.png');
    }

    &.missoes{
        background-image: url('../../../assets/images/participante/bg-missoes.png');
    }

    &.jogos{
        background-image: url('../../../assets/images/participante/bg-jogos.png');
    }

    &.ranking{
        background-image: url('../../../assets/images/participante/bg-ranking.png');
    }

    &:nth-child(2),
    &:nth-child(3){
        margin-bottom: 20px;
    }

    &:nth-child(2),
    &:nth-child(4){
        margin-right: 4%;
    }
}

.home-participante-modulos-title{
    color: $white-text;
    display: block;
    font-family: 'Muli Bold', sans-serif;
    font-size: 16px;
    line-height: 16px;
    margin-top: 10px;
}