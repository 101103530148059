@import '../../../../assets/styles/abstracts/variables';

// Main
.oficina-main{
    min-height: calc(100vh - 200px);
    padding: 70px 30px 30px;
    position: relative;

    &:after{
        background: url('../../../../assets/images/oficinas/icon-oficinas.png');
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 50%;
        content: '';
        height: 110px;
        left: 50%;
        position: absolute;
        top: -55px;
        transform: translateX(-50%);
        width: 110px;
    }
}

// Titles
.oficina-main-title{
    font-family: 'DK Aderyn', sans-serif;
    font-size: 26px;
    letter-spacing: 1.3px;

    &.oficina-name{
        color: $blue-title;
    }

    &.content{
        color: $orange-title;
        margin: 15px 0;;
    }
}

// Menu List - nav carousel
.oficina-main-menu{
    display: flex;
    justify-content: space-between;
    list-style: none;
}

.oficina-main-menu-item {
  color: $blue-text;
  font-family: "Muli Light", sans-serif;
  font-size: 16px;

  &.active {
    border-bottom: 2px solid #3e68b2;
    font-weight: bold;
  }
}

// Progresso - Campanha
// Timeline
.campanha-instrutor-timeline{
    background: $blue-bg;
    border-radius: 9px;
    margin-bottom: 20px;
    padding: 15px 10px;

    h4{
        color: $white-text;
        font-family: 'Muli Bold', sans-serif;
        font-size: 18px;
        margin: 0 0 8px;
    }
}

// Progresso
.campanha-instrutor-timeline-progress{
    margin-bottom: 15px;

    svg{
        fill: $white-bg;

        @media(max-width: 330px){
            width: 18px;
        }
    }

    div{
        align-items: center;
        display: inline-flex;
        margin: 0 8px;
    }

    .timeline-list-item{
        background: $white-bg;
        border: 1px solid #F69522;
        border-radius: 50%;
        color: $black-text;
        display: inline-block;
        font-family: 'Muli Bold', sans-serif;
        font-size: 16px;
        height: 28px;
        width: 28px;

        &.active{
            background: $orange-button;
            border-color: $white-bg;
            color: $white-text;
        }
    }

    .timeline-list-item-divider{
        background: $orange-bg;
        height: 2px;
        margin: 0 3px;
        width: 10px;

        &:last-child{
            display: none;
        }

        @media(max-width: 330px){
            width: 6px;
        }
    }
}
