@import "../../../../assets/styles/abstracts/variables";

.forgot {
  text-align: center;
}

.forgot-main {
  padding: 30px 40px;
  width: 100%;
}

// Subtitle and text
.forgot-subtitle,
.forgot-text{
  font-size: 19px;
  line-height: 26px;
}

.forgot-subtitle{
  color: $orange-title;
  font-family: 'Muli Bold', sans-serif;
  letter-spacing: 0.5px;
}

.forgot-text{
  color: $blue-text;
  font-family: 'Muli', sans-serif;
}

// Label e Input
.forgot-label,
.forgot-input{
  display: block;
}

.forgot-label{
  color: $label-text;
  font-family: 'Muli Bold', sans-serif;
  font-size: 18px;
}

.forgot-input{
  border-bottom: 0.5px solid #000000;
  color: $link-text;
  font-family: 'Muli Light', sans-serif;
  font-size: 13px;
  line-height: 35px;
  margin: 0 auto;
  width: 80%;
  text-align: center;
}

// Button
.forgot-btnRecover{
  border: 2px solid $orange-button;
  color: $orange-text;
  margin: 45px 0 25px;
}

// Back Link
.forgot-backLink{
  color: $blue-text;
  font-family: 'Muli', sans-serif;
  font-size: 16px;
}