@import "../../../../assets/styles/abstracts/variables";

.skin-color{
  .header-dropdown{
    display: none;
  }
}

// Avatar Item
.skin-item {
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  height: 70px;
  margin: 30px 20px 0 0;
  min-width: 70px;

  .input-color {
    height: 0;
    left: -9999px;
    position: absolute;
    width: 0;
  }

  .input-color + .label-color {
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 70px;
    width: 70px;
  }

  .input-color:checked + .label-color {
    border: 3px solid $orange-bg;
    color: #fff;
    z-index: 1;
  }
}