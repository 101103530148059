@import '../../../assets/styles/abstracts/variables';

.login-instrutor{
    text-align: center;
}

// Main
.login-instrutor-main{
    padding: 30px 40px;
    width: 100%;
}

// Titles
.login-instrutor-title{
    color: $orange-title;
    font-family: 'Muli Bold', sans-serif;
    font-size: 19px;

    span{
        color: $blue-title;
        display: block;
        font-family: 'Muli', sans-serif;
        margin-top: 5px;
    }
}

// Form
.login-instrutor-label{
    color: $label-text;
    display: block;
    font-family: 'Muli Bold', sans-serif;
    font-size: 18px;
    margin: 20px 0;
}

.login-instrutor-input{
    border-bottom: 0.5px solid #000;
	color: $input-text;
	display: block;
	font-family: 'Muli Light', sans-serif;
    font-size: 13px;
    line-height: 30px;
    margin: 0 auto;
    outline: none;
	text-align: center;
    width: 80%;
}

// Esqueceu Login
.login-instrutor-forgot {
    color: $blue-text;
    display: block;
    font-family: 'Muli Light', sans-serif;
    font-size: 13px;
    margin-top: 10px;
}

.login-instrutor-submit{
	background: $orange-button;
	color: $white-text;
    margin-top: 40px;
    outline-style: none;
}

// Buttons
// Quero ser Instrutor
.login-instrutor-cadastro{
    background: $blue-button;
    color: $white-text;
    margin-top: 30px;
}

// Voltar - link
.login-instrutor-back{
    color: $blue-text;
    display: block;
    font-family: 'Muli', sans-serif;
    font-size: 16px;
    margin-top: 45px;
}