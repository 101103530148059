@import "../../../assets/styles/abstracts/variables";

// Header
.home-instrutor {
  .header-arrow {
    display: none;
  }
}

.home-instrutor-main {
  background: none;
  height: 100%;
  padding-top: 70px;
}

.home-instrutor-header {
  height: 45vh;
  position: relative;
}

// Welcome
.home-instrutor-welcome {
  color: $black-text;
  display: inline-block;
  font-family: "Muli Light", sans-serif;
  font-size: 36px;
  left: 35px;
  position: absolute;
  text-align: left;

  @media (max-width: 360px) {
    font-size: 30px;
  }

  .instrutor-name {
    display: block;
    font-family: "Muli Semibold";
  }
}

// Avisos
.home-instrutor-avisos {
  display: inline-block;
  position: absolute;
  right: 20px;

  @media (max-width: 360px) {
    right: 0;
  }

  .instrutor-conquista-title {
    font-family: "DK Aderyn", sans-serif;
    font-size: 19px;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .instrutor-quadro {
    align-items: center;
    background-image: url("../../../assets/images/instrutor/home-avisos.png");
    background-repeat: no-repeat;
    background-size: contain;
    display: grid;
    font-family: "Muli Bold", sans-serif;
    height: 220px;
    padding: 30px;
    width: 170px;

    @media (max-width: 360px) {
      height: 200px;
      width: 150px;
    }

    .instrutor-titles {
      color: $black-text;
      font-size: 14px;
      margin: 0;
      text-transform: uppercase;
    }

    span {
      color: $orange-title;
      display: block;
      font-size: 14px;
      text-transform: uppercase;
    }
  }
}

// Content
.home-instrutor-content {
  background-color: $content-bg;
  border-top-left-radius: 100% 10%;
  border-top-right-radius: 100% 10%;
  padding: 100px 30px 30px;
  position: relative;
  text-align: center;

  @media (max-width: 360px) {
    padding: 70px 20px 30px;
  }

  img {
    position: absolute;
    left: 20px;
    top: -147px;
    width: 230px;

    @media (max-width: 360px) {
      top: -102px;
      width: 160px;
    }
  }
}

// Button
.home-instrutor-button {
  align-items: center;
  border-radius: 10px;
  color: $white-text;
  display: flex;
  font-family: "Muli Bold", sans-serif;
  font-size: 24px;
  justify-content: space-around;
  padding: 20px 25px;
  width: 100%;
  cursor: pointer;

  &.turmas {
    background: $blue-bg;
  }

  &.agendamentos {
    background: $orange-bg;
    margin: 20px 0;
  }

  &.treinamento {
    background: $yellow-bg;
    margin: 20px 0;
  }

  &.material {
    background: $green-bg;
  }

  svg {
    fill: $white-bg;
    width: 40px;
  }

  span {
    background: $white-bg;
    height: 40px;
    width: 1px;
  }
}
