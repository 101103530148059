@import "../../../../../../assets/styles/abstracts/variables";

.approve-mission-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100px;
    
    button {
      background: $green-bg;
      border-radius: 30px;
      color: $white-text;
      cursor: pointer;
      font-family: "Muli Bold", sans-serif;
      font-size: 9px;
      padding: 5px 10px;
      text-transform: uppercase;
    }
  }

.form-group-checkbox input + label:after {
  content: '';
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: rotate(0deg);
  border-radius: 0;
  width: 15px;
  height: 15px;
  left: 7px;
  top: 2.5px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  transform-origin: bottom left;
}
.form-group-checkbox input:checked + label:after {
  transform: scale(1);
  border-radius: 0;
  width: 15px;
  height: 15px;
  left: 7px;
  top: 2.5px;
}

.form-group-checkbox {
  user-select: none;
}