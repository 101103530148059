@import '../../../../assets/styles/abstracts/variables';

.quiz-instrutor{
    .header-arrow{
        display: none;
    }
}

// Main
.quiz-instrutor-main{
    padding: 70px 30px 30px;
    position: relative;

    &:after{
        background: url('../../../../assets/images/instrutor/icon-quiz.png');
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 50%;
        content: '';
        height: 110px;
        left: 50%;
        position: absolute;
        top: -55px;
        transform: translateX(-50%);
        width: 110px;
    }
}

// Pergunta
.quiz-instrutor-question{
    color: $orange-text;
    font-family: 'Muli Bold', sans-serif;
    font-size: 19px;
    line-height: 21px;
}

// Alternativas
.quiz-instrutor-answers{
    margin: 20px 0;
}

.quiz-instrutor-item{
    cursor: pointer;
    margin-bottom: 15px;

    input {
        height: 0;
        left: -9999px;
        position: absolute;
        width: 0;
    }

    label{
        align-items: center;
        color: $blue-text;
        display: inline-flex;
        font-family: 'Muli', sans-serif;
        font-size: 14px;
        line-height: 19px;
        padding-right: 10%;
        position: relative;
        text-align: left;
        width: 100%;

        &:before{
            content: '';
            cursor: pointer;
            background-color: transparent;
            border: 2px solid #F69522;
            border-radius: 50%;
            display: inline-block;
            height: 30px;
            position: absolute;
            vertical-align: middle;
            width: 30px;
            right: 0;
        }
    }

    input:checked + label:after {
        background: #3E68B2;
        border-radius: 50%;
        content: '';
        height: 10px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);    
        width: 10px;
    }
}

// Botão
.quiz-instrutor-button{
    background: $orange-button;
    color: $white-text;
}