@import "../../../../assets/styles/abstracts/variables";

// Main
.create-turma-main {
  padding: 70px 30px 30px;
  position: relative;

  &:after {
    background: url("../../../../assets/images/instrutor/turma-icon.png");
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    content: "";
    height: 110px;
    left: 50%;
    position: absolute;
    top: -55px;
    transform: translateX(-50%);
    width: 110px;
  }
}

// Text
.create-turma-text {
  color: $green-text;
  font-family: "Muli Bold", sans-serif;
  font-size: 18px;
  line-height: 25px;
}

// Alert
.create-turma-alert {
  color: $red-text;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

// Title
.create-turma-title {
  color: $link-text;
  font-family: "Muli Bold", sans-serif;
  font-size: 18px;
}

// Create Turma Button
.create-turma-oficina {
  margin-top: 20px;
}

// Form
.card {
  margin-bottom: 20px;
}
// Label
.create-turma-label {
  color: $label-text;
  display: block;
  font-family: "Muli Bold", sans-serif;
  font-size: 18px;
}

// Input
.create-turma-input {
  border-bottom: 0.5px solid #000000;
  color: $input-text;
  display: block;
  font-family: "Muli Light", sans-serif;
  font-size: 13px;
  line-height: 25px;
  margin: 0 auto 20px;
  outline: none;
  text-align: center;
  width: 60%;

  &.endereco {
    width: 80%;
  }
}

// Radio Input
.create-turma-input-radio {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 20px;

  input {
    height: 0;
    left: -9999px;
    position: absolute;
    width: 0;
  }

  input + label {
    background-color: #fff;
    border: 1px solid #3e68b2;
    border-radius: 5px;
    box-sizing: border-box;
    color: $blue-text;
    cursor: pointer;
    display: inline-block;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    line-height: 140%;
    margin: 0;
    padding: 3px 5px !important;
    position: relative;
    text-align: center;
    width: 105px;
  }

  input:checked + label {
    background-color: $blue-button;
    color: #fff;
    z-index: 1;
    padding: 3px 5px;
  }
}

// Select
.create-turma-select-arrow {
  border-bottom: 0.5px solid #000000;
  cursor: pointer;
  display: flex;
  margin: 15px auto;
  width: 60%;

  &:after {
    content: "";
    left: 75%;
    padding: 9px 0 0 0;
    pointer-events: none;
    position: absolute;
    transform: rotate(45deg);
    width: 12px;
    border: solid #2699fb;
    border-width: 0 3px 3px 0;
  }

  .create-turma-select {
    color: $input-text;
    font-family: "Muli Light", sans-serif;
    font-size: 13px;
    padding-bottom: 10px;
    text-align: center;
    appearance: none;
    position: relative;
    text-align-last: center;
    vertical-align: middle;
    width: 100%;
    outline: none;
  }
}

// Add Button
.create-turma-add-button {
  background: $green-bg;
  color: $white-text;
  font-size: 12px;
  margin: 0 auto;
  padding: 5px 0;
  width: 80%;
}

.create-turma-add-button:disabled {
    background: $disabled-button;
    cursor: not-allowed;
  }

// Info text
.create-turma-info {
  color: $blue-text;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

// Lista de Participantes
.create-turma-participantes-list {
  li {
    align-items: center;
    border-bottom: 1px solid rgba(112, 112, 112, 0.5);
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  // Nome e Data de nascimento
  .participantes-info {
    display: inline-block;
  }

  .participantes-name,
  .participantes-date {
    display: block;
    font-family: "Muli", sans-serif;
    text-align: left;
  }

  .participantes-name {
    color: $blue-text;
    font-size: 17px;
  }

  .participantes-date {
    font-size: 15px;
  }

  // Botões
  .participantes-buttons {
    display: inline-block;
  }

  .participantes-edit,
  .participantes-remove {
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 27px;
    width: 27px;
  }

  .participantes-remove {
    margin-left: 15px;
  }
}

// Lista de Agendamentos
.create-turma-agendamento-list {
  li {
    border-bottom: 1px solid rgba(112, 112, 112, 0.5);
    margin-bottom: 10px;
    text-align: left;

    &:last-child {
      border: none;
    }
  }

  .oficina-actions {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  // Nome e Data de nascimento
  .oficina-info {
    display: inline-block;
  }

  .oficina-name,
  .oficina-date,
  .oficina-address {
    display: block;
    font-family: "Muli", sans-serif;
  }

  .oficina-name {
    color: $blue-text;
    font-size: 17px;
  }

  .oficina-date,
  .oficina-address {
    font-size: 15px;
  }

  // Botões
  .oficina-buttons {
    display: inline-block;

    button:disabled {        
        opacity: 0.5;
        img {
            cursor: not-allowed;
        }
    }
  }

  .oficina-edit,
  .oficina-remove {
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 27px;
    width: 27px;
  }

  .oficina-remove {
    margin-left: 15px;
  }
}

// Save Button
.create-turma-save {
  background-color: #3e68b2 !important;
  color: $white-text;
  margin-top: 20px;
}

.swal2-styled.swal2-cancel {
  background-color: white;
  color: #E81E47;
  border: 2px solid #E81E47;
}