@import "../../../../../assets/styles/abstracts/variables";



.cardImagem-item{
    width: 254px;
    height: 124px;
    border: 1px dashed #B8B8B8;
    border-radius: 7px;
    background-image: url("../../../../../assets/images/instrutor/sendmission-image-plate.png");
    background-position: center;
    background-size: 254px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
}

.swal2-popup {
    width: 22rem;
}

.cardImagem-form {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    color: grey;

    label {
        margin-bottom: 20px;
        color: grey;
    }

    select {
        margin-bottom: 20px;
        padding: 10px;
    }
}

.cardImagem-item > button {
    color: white;
    background: #87BF40;
    font-size: 30px;
    font-weight: bold;
    padding: 6px 15px;

    position: relative;
    left: 21px;
    top: 16px;
    border-radius: 100%;
    cursor: pointer;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

//select
.cardImagem_selectParticipant {
    padding-left: 1px;
}

.cardImagem_inputParticpant {
    padding-left: 14px;
}

.cardImagem-dadosDoParticipante {
    font-size: 0.8rem;
    max-width: 254px;
    display: flex;
    color: #5E6166;
    flex-direction: column;
    font-weight: 700;
    align-items: flex-start;

    .cardImagem-nomeDoParticipante {
        margin-bottom: 10px;
        margin-left: 0px;

        width: 254px;
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        span {
            position: relative;
            margin-right: 5px;
            /* float: right; */
            background: red;
            border-radius: 100px;
            width: 19px;
            color: white;
            cursor: pointer;
        }
    }

    .cardImagem-missaoDoParticipante {
        text-align: left;
        margin: 0;
    }
}

.adicionarImagem-modalSaveButton {
    background-color: #87BF40!important;

    &:disabled {
        background-color: #B8B8B8!important;
    }
}

.adicionarimagem-modalerrortext {
    margin-bottom: 0;
}