@import '../../../../assets/styles/abstracts/variables';

.page-title-header{
    .imgCertified{
        position: relative;
        width: 100px;

        &:after{
            background-repeat: no-repeat;
            background-size: contain;
            border-radius: 50%;
            left: 50%;
            position: absolute;
            top: -55px;
            transform: translateX(-50%);
            width: 50px;
        }
    }
}

// Intro Text
.certificado-treinamento-main-intro{
    color: black;
    font-family: 'Muli Bold', sans-serif;
    font-size: 18px;
    text-align: center;
    line-height: 22px;
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    margin: 24px 0 30px;
}

// Card
$small: 400px;
$medium: 720px;


.certificado-treinamento-card{
    border: 2px solid #008080;
    padding: 30px 1rem;
    margin: 0 50px 50px;

    .logo-image{
        padding: 0 30px;
        width: 300px;
    }

    .certificado-title{
        color: #008080;
        font-family: 'Bold', sans-serif;
        font-size: 30px;
        line-height: 22px;
        margin: 40px 0;
        @media screen and (max-width: $medium) {
            font-size: 1.6rem;
            line-height: 1.7rem;
        }
    }

    .certificado-text{
        color: $black-text;
        font-family: 'Arial', sans-serif;
        font-size: 22px;
        line-height: 25px;
        margin-left: 150px;
        margin-right: 150px;
        @media screen and (max-width: $medium) {
            font-size: 1.5rem;
            line-height: 1.6rem;
            margin: 0;
        }

        .intro{
            display: block;
            font-size: 30px;
        }

        .name{
            color: #000;
            display: block;
            font-family: "Arial", sans-serif;
            font-size: 2rem;
            letter-spacing: 0.5px;
            margin: 40px 0;
            text-transform: uppercase;
            font-weight: bold;
        }

        .text {
            font-style: italic;
        }
    }

    .certificado-date{
        font-family: 'Roboto', sans-serif;
        font-size: 22px;
    }

    .assinatura-image{
        display: block;
        margin: 0 auto;
        width: 130px;
    }

    .certificado-legenda{
        color: $black-text;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 20px;
        margin-bottom: 0;
    }
}

// Botões
.certificado-treinamento-button{
    display: flex;
    justify-content: center;

    &.filled{
        width: 90vw;
        background: $orange-bg;
        border: 2px solid #F69522;
        color: $white-text;
        margin: 20px auto;
    }

    &.outline{
        width: 90vw;
        border: 2px solid #F69522;
        margin: 0 auto 20px;
        a{
            color: $orange-button;
        }
    }
}