@import "../../../../assets/styles/abstracts/variables";

// Main
.ended-classes-main {
  padding: 70px 30px 30px;
  position: relative;

  &:after {
    background: url("../../../../assets/images/instrutor/turma-icon.png");
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    content: "";
    height: 110px;
    left: 50%;
    position: absolute;
    top: -55px;
    transform: translateX(-50%);
    width: 110px;
  }
}

.ended-classes-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: $value-text;
    display: block;
    font-family: "Muli", sans-serif;
    font-size: 16px;
    margin: 5px 0;

    &.name {
      color: $blue-title;
      font-family: 'Muli Bold', sans-serif;
      font-size: 18px;
      text-transform: uppercase;
    }

    span{
      color: $blue-title;
      font-family: 'Muli Bold', sans-serif;
    }
  }

  // .info-icon{
  //   fill: $blue-bg;
  //   margin-left: 10px;
  //   position: absolute;
  //   right: 20px;
  //   width: 20px;
  // }

  .edit-turma{
    font-family: 'Muli Bold', sans-serif;
    margin-top: 10px;
    padding:5px 43px ;
    cursor: pointer;
    font-size: 13px;
    color: $green-title;
    background-color: $white-bg;
    border: 2px solid $green-title;
    border-radius: 30px;
    outline-style: none;
  }
  
  .info-turma{
    display: block;
    text-align: center;
    font-family: 'Muli Bold', sans-serif;
    margin-top: 10px;
    //margin-left: 203px;
    padding:5px 10px ;
    cursor: pointer;
    font-size: 13px;
    color: $green-title;
    background-color: $white-bg;
    border: 2px solid $green-title;
    border-radius: 30px;
    text-transform: uppercase;
    outline-style: none;
  }

  .certificado-turma{
    display: block;
    text-align: center;
    font-family: 'Muli Bold', sans-serif;
    margin-top: 10px;
    //margin-left: 203px;
    padding:5px 10px ;
    cursor: pointer;
    font-size: 13px;
    color: $green-title;
    background-color: $white-bg;
    border: 2px solid $green-title;
    border-radius: 30px;
    text-transform: uppercase;
    outline-style: none;
  }

}
