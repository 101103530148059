@import '../../../../assets/styles/abstracts/variables';

// Main
.game-types-main{
    min-height: calc(100vh - 200px);
    padding: 70px 30px 30px;
    position: relative;

    &:after{
        background: url('../../../../assets/images/participante/icon-jogos.png');
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 50%;
        content: '';
        height: 110px;
        left: 50%;
        position: absolute;
        top: -55px;
        transform: translateX(-50%);
        width: 110px;
    }

    .owl-stage-outer{
        position: relative;
    }

    .owl-nav{
        color: $orange-text;
        font-size: 60px;
        height: 100px;
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;

        .owl-prev,
        .owl-next{
            position: absolute;
        }

        .owl-prev{
            left: -15px;
        }

        .owl-next{
            right: -15px;
        }
    }
}

// Módulos
.game-types-modulos{
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px;
    cursor: pointer;
    display: inline-block;
    height: 50vh;
    margin-top: 20px;
    padding-top: 50px;
    position: relative;
    width: 90%;


    &.quiz{
        background-image: url('../../../../assets/images/participante/bg-missoes.png');
    }

    &.trueFalse{
        background-image: url('../../../../assets/images/participante/bg-oficina.png');
    }

    &.fillGap{
        background-image: url('../../../../assets/images/participante/bg-jogos.png');
    }

    img{
        bottom: -10px;
        max-height: 200px;
        object-fit: contain;
        position: absolute;

        @media(max-width: 360px){
            max-height: 170px;
        }
    }
}

.game-types-modulos-title{
    color: $white-text;
    display: block;
    font-family: 'Muli Bold', sans-serif;
    font-size: 18px;
    line-height: 25px;
    margin: 0 auto;
    text-transform: uppercase;
    width: 50%;
}