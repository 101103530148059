@import "../../../../../assets/styles/abstracts/variables";

.estrelas-instrutor-modal {
  // Modal Title
  .modal-title {
    color: $orange-title;
    font-family: "DK Lemon", sans-serif;
    font-size: 29px;
    letter-spacing: 3px;
    margin: 0;
  }

  // Participant Name
  .modal-participant-name {
    color: $value-text;
    font-family: "Muli Bold", sans-serif;
    font-size: 18px;
    margin: 0 0 10px;

    span {
      color: $blue-text;
      font-family: "Muli", sans-serif;
      font-size: 15px;
    }
  }

  // Subtitle
  .modal-subtitle {
    color: $label-text;
    font-family: "Muli Bold", sans-serif;
    font-size: 18px;
    margin: 0;
  }

  // Form
  .modal-form {
    position: relative;

    .modal-input {
      height: 0;
      left: -9999px;
      position: absolute;
      width: 0;
    }

    .modal-label {
      align-items: center;
      color: $value-text;
      cursor: pointer;
      display: inline-flex;
      font-family: "Muli", sans-serif;
      font-size: 14px;
      height: 50px;
      line-height: 19px;
      padding-left: 10%;
      position: relative;
      text-align: left;
      width: 100%;

      &:before {
        content: "";
        cursor: pointer;
        background-color: transparent;
        border: 2px solid #f69522;
        border-radius: 50%;
        display: inline-block;
        height: 20px;
        left: 0;
        position: absolute;
        vertical-align: middle;
        width: 20px;
      }
    }

    .modal-input:checked + .modal-label:after {
      background: $orange-bg;
      border-radius: 50%;
      content: "";
      height: 10px;
      left: 5px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
    }
  }

  .modal-star-icon {
    position: absolute;
    right: 0;
    width: 45px;

    &.incomplete{
        fill: none;
        stroke: $disabled-button;
        stroke-dasharray: 2.5;
    }

    &.complete{
        fill: $green-bg;
    }

    &.later{
        fill: $orange-bg;
    }
  }
}
