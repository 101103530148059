@import "../../../../assets/styles/abstracts/variables";

// Main
.conteudo-treinamento-main {
  padding: 0 30px 30px;
}

// Vídeo
.conteudo-treinamento-video {
  position: relative;
  top: -40px;
}
.conteudo-video{
  margin:20px 0 30px 0;
}

// Card
.conteudo-treinamento-card {
  background: $white-bg;
  border-radius: 9px;
  box-shadow: 0 3px 6px #00000029;
  margin-bottom: 15px;
  padding: 15px;

  input {
    display: none;
    visibility: hidden;
  }

  .conteudo-treinamento-card-text {
    border-top: 1px solid rgba(112, 112, 112, .4);
    color: $poder-text;
    display: none;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    overflow: hidden;
    padding-top: 10px;
    transition: display 1000ms ease;
  }

  input:checked ~ .conteudo-treinamento-card-text {
    display: block;
  }
}

// Card - Header
.conteudo-treinamento-card-title {
  align-items: center;
  display: flex;
  justify-content: space-between;

  h3 {
    color: $blue-title;
    display: inline-block;
    font-family: "Dk Aderyn", sans-serif;
    font-size: 22px;
    letter-spacing: 1px;
    margin: 0;
    text-align: left;
  }

  span {
    border: solid #2699fb;
    border-width: 0 3px 3px 0;
    display: inline-block;
    height: 11px;
    transform: rotate(-45deg);
    width: 11px;
  }
}

// Carousel
.conteudo-treinamento-card-text{
  // Nav
  .owl-nav{
    bottom: -9px;
    color: #222222;
    display: flex;
    font-size: 30px;
    justify-content: space-around;
    position: absolute;
    width: 100%;
  }

  // Dots
  .owl-dots{
    margin-top: 15px;
  }

  .owl-dot{
    background: #EEEEEE !important;
    border: 1px solid #707070 !important;
    border-radius: 50%;
    height: 10px;
    margin: 0 20px;
    width: 10px;

    &:first-child,
    &:last-child{
      margin: 0;
    }

    &.active{
      border-color: #515151 !important;
      background: #515151 !important;
    }
  }
}

// Botão
.conteudo-treinamento-button {
  background: $orange-button;
  color: $white-text;
  margin-top: 20px;
}