@import '../../../assets/styles/abstracts/variables';

// Main
.list-estrelas-main{
    min-height: calc(100vh - 200px);
    padding: 70px 30px 30px;
    position: relative;

    &:after{
        background: url('../../../assets/images/participante/icon-estrelas.png');
        background-repeat: no-repeat;
        background-size: contain;
        // background: $orange-bg;
        border-radius: 50%;
        content: '';
        height: 110px;
        left: 50%;
        position: absolute;
        top: -55px;
        transform: translateX(-50%);
        width: 110px;
    }
}

// List
.list-estrelas-header{
    display: flex;
    justify-content: space-between;

    span{
        color: $orange-text;
        font-family: 'Muli', sans-serif;
        font-size: 18px;

        &:first-child{
            text-align: left;
            width: 70%;
        }

        &:last-child{
            width: 30%;
        }
    }
}

// List Item
.list-estrelas-item{
    border-bottom: 1px solid rgba(112, 112, 112, .5);
    display: block;
    padding: 10px 0;

    &:first-child{
        padding-top: 0;
    }

    &:last-child{
        border: none;
        padding-bottom: 0;
    }

    .participant-info,
    .star-info{
        display: inline-block;
    }

    .participant-info{
        width: 70%;

        span{
            color: $value-text;
            display: block;
            font-family: 'Muli', sans-serif;
            font-size: 15px;
            text-align: left;

            &:first-child{
                color: $blue-text;
                font-weight: bold;
                text-transform: uppercase;
            }

            &:last-child{
                text-transform: capitalize;
            }
        }
    }

    .star-info{
        width: 30%;

        .icon,
        span{
            display: block;
        }

        .icon{
            margin: 0 auto;
            width: 35px;

            &.complete{
                fill: $green-bg;
            }

            &.half{
                fill: $orange-bg;
            }
        }
    }
}

// Button
.list-estrelas-button{
    border: 1px solid $green-bg;
    color: $green-bg;
    margin-top: 20px;
    padding: 7px 0;
}