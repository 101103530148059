@import '../../assets/styles/abstracts/variables';

.loading-container {
    top: 0;
    z-index: 999999;
    background-color: #FFF3E7;
    position: relative;
    text-align: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.loading {
    z-index: 9999;
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading > svg {
    width: 150px;
    margin-bottom: -130px;
    height: 60px;
}

.loader-2 {
    display: block;
      height: 128px;
      width: 128px;
      -webkit-animation: loader-2-1 3s linear infinite;
              animation: loader-2-1 3s linear infinite;
  }
  @-webkit-keyframes loader-2-1 {
      0%   { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes loader-2-1 {
      0%   { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }
  .loader-2 span {
      display: block;
      position: absolute;
      top: 0; left: 0;
      bottom: 0; right: 0;
      margin: auto;
      height: 128px;
      width: 128px;
      clip: rect(16px, 128px, 128px, 0);
      -webkit-animation: loader-2-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
              animation: loader-2-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  }
  @-webkit-keyframes loader-2-2 {
      0%   { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes loader-2-2 {
      0%   { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }
  .loader-2 span::before {
      content: "";
      display: block;
      position: absolute;
      top: 0; left: 0;
      bottom: 0; right: 0;
      margin: auto;
      height: 128px;
      width: 128px;
      border: 3px solid transparent;
      border-top: 3px solid #8dbc29;
      border-radius: 50%;
      -webkit-animation: loader-2-3 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
              animation: loader-2-3 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  }
  @-webkit-keyframes loader-2-3 {
      0%   { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes loader-2-3 {
      0%   { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }
  .loader-2 span::after {
      content: "";
      display: block;
      position: absolute;
      top: 0; left: 0;
      bottom: 0; right: 0;
      margin: auto;
      height: 128px;
      width: 128px;
      border: 3px solid rgb(141, 188, 41);
      border-radius: 50%;
  }
  
  .fadeOut{
    opacity:0;
    transition: opacity 0.5s;

}
.fadeIn{
    opacity:1;
    transition: opacity 0.5s 0.5s;

}