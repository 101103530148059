@import '../../../../assets/styles/abstracts/variables';

// Main
.list-treinamento-main{
    padding: 70px 30px 30px;
    position: relative;

    &:after{
        background: url('../../../../assets/images/instrutor/icon-quiz.png');
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 50%;
        content: '';
        height: 110px;
        left: 50%;
        position: absolute;
        top: -55px;
        transform: translateX(-50%);
        width: 110px;
    }
}

// Texts
.list-treinamento-text-intro,
.list-treinamento-text-alert{
    font-family: 'Muli Bold', sans-serif;
    padding: 0 20px;
}

.list-treinamento-text-intro{
    color: $blue-text;
    font-size: 18px;
    margin: 0;
}

.list-treinamento-text-alert{
    color: $alert-text;
    font-size: 16px;
}

.nao-concluido{
    background: $blue-bg;
}
.concluido{
    background: $green-bg;

    .list-treinamento-trilha-image{

        &:before{
            border: 2px solid $green-bg;
        }
    }

}

.depoimento {
    background: $orange-bg;

    .list-treinamento-trilha-image{

        &:before{
            border: 2px solid $orange-bg;
        }
    }
}



// Lista de Trilhas
.list-treinamento-trilha{
    align-items: center;
    border-radius: 7px;
    display: flex;
    height: 64px;
    margin-bottom: 15px;
    width: 100%;
    cursor: pointer;

    @media(max-width: 330px){
        height: 90px;
    }
}

// Trilhas - Image
.list-treinamento-trilha-image{
    border-radius: 7px 0 0 7px;
    display: inline-block;
    height: 64px;
    position: relative;
    width: 86px;

    @media(max-width: 330px){
        height: 90px;
    }

    &:before{
        background: #E9F3FC;
        border: 2px solid #3e68b2;
        border-radius: 7px 0 0 7px;
        content: '';
        height: 64px;
        left: 0;
        position: absolute;
        width: 86px;

        @media(max-width: 330px){
            height: 90px;
        }
    }

    svg{
        height: 100%;
        padding: 12px 10px;
        position: relative;
        width: 100%;
    }

}

.list-treinamento-trilha-divider{
    background: #E9F3FC;
    display: inline-flex;
    height: 22px;
    margin: 21px 13px;
    width: 1px;

    @media(max-width: 330px){
        height: 50px;
    }
}

// Trilhas - Textos
.list-treinamento-trilha-content{
    display: block;
    padding-right: 10px;
    width: calc(100% - 138px);
}

.list-treinamento-trilha-content-title,
.list-treinamento-trilha-content-status{
    color: $white-text;
    font-size: 12px;
    text-align: center;
}

.list-treinamento-trilha-content-title{
    font-family: 'Muli Bold', sans-serif;
    letter-spacing: 0.5px;
    line-height: 16px;
    margin: 0;
    text-transform: uppercase;
}

.list-treinamento-trilha-content-status{
    font-family: 'Muli', sans-serif;
    letter-spacing: 1px;
}

// Arrow
.list-treinamento-trilha-arrow{
    border: solid #fff;
    border-width: 0 3px 3px 0;
    height: 11px;
    margin: 25px 0;
    transform: rotate(-45deg);
    width: 11px;
}

// Buttons
.list-treinamento-buttons{
    display: flex;
    justify-content: space-between;
}

.list-treinamento-back,
.list-treinamento-finalizar{
    border-radius: 30px;
    cursor: pointer;
    font-family: 'Muli Bold', sans-serif;
    font-size: 16px;
    height: 40px;
    text-transform: uppercase;
    width: 170px;
}

.list-treinamento-back{
    align-items: center;
    border: 2px solid #87BF40;
    display: inline-flex;
    color: $green-text;
    justify-content: center;
}

.list-treinamento-view-certified {
    border-radius: 30px;
    cursor: pointer;
    font-family: 'Muli Bold', sans-serif;
    font-size: 16px;
    height: 40px;
    text-transform: uppercase;
    width: 190px;
    align-items: center;
    display: inline-flex;
    color: white;
    justify-content: center;
    background-color: #F69522;
}

.list-treinamento-finalizar{
    background: $disabled-button;
    color: $white-text;
    padding: 7px 0;
    opacity: 0.5;
    cursor: not-allowed;

    &.finalizado{
        background: $green-bg;
        cursor: pointer;
        opacity: initial;
    }
}