@import "../../../../assets/styles/abstracts/variables";

// Main
.game-main {
  min-height: calc(100vh - 200px);
  padding: 70px 30px 30px;
  position: relative;

  &:after {
    background: url('../../../../assets/images/participante/icon-jogos.png');
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    content: "";
    height: 110px;
    left: 50%;
    position: absolute;
    top: -55px;
    transform: translateX(-50%);
    width: 110px;
  }
}

// Collapse
.game-collapse {
  background: $white-bg;
  border-radius: 9px;
  box-shadow: 0 3px 6px #00000029;
  margin-bottom: 15px;
  padding: 15px;

  input {
    display: none;
    visibility: hidden;
  }

  .game-collapse-text {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    color: $value-text;
    display: none;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 18px;
    margin: 0;
    overflow: hidden;
    padding-top: 10px;
    transition: display 1000ms ease;
  }

  input:checked ~ .game-collapse-text {
    display: block;
  }
}

// Collapse - Header
.game-collapse-title {
  align-items: center;
  display: flex;
  justify-content: space-between;

  h3 {
    color: $orange-title;
    display: inline-block;
    font-family: "Dk Aderyn", sans-serif;
    font-size: 22px;
    letter-spacing: 1px;
    margin: 0;
    text-align: left;
  }

  span {
    border: solid $orange-bg;
    border-width: 0 3px 3px 0;
    display: inline-block;
    height: 11px;
    transform: rotate(-45deg);
    width: 11px;
  }
}

// Question
.game-question {
  color: $value-text;
  font-family: "Muli", sans-serif;
  font-size: 16px;
  line-height: 18px;
}

// V ou F
.game-content-text{
    font-family: 'Muli Bold', sans-serif;
    font-size: 15px;
    line-height: 20px;
    text-align: justify;

    p{
        margin: 10px 0;
    }
}

// Alternativas
.game-question-alternatives {
  position: relative;

  &:nth-child(even) {
    margin: 20px 0;
  }

  &:last-child {
    margin: 0;
  }

  .game-input {
    height: 0;
    left: -9999px;
    position: absolute;
    width: 0;
  }

  .game-label {
    align-items: center;
    color: $value-text;
    cursor: pointer;
    display: inline-flex;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    line-height: 19px;
    padding-left: 15%;
    position: relative;
    text-align: left;
    width: 100%;

    &:before {
      content: "";
      border: 2px solid #f69522;
      border-radius: 50%;
      display: inline-block;
      height: 20px;
      left: 15px;
      position: absolute;
      vertical-align: middle;
      width: 20px;
    }
  }

  .game-input:checked + .game-label:after {
    background: $orange-bg;
    border-radius: 50%;
    content: "";
    height: 10px;
    left: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
  }
}

// Button
.game-button {
  background: $green-bg;
  color: $white-text;
  margin-top: 20px;
}
