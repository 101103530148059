html {
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
}

// Main Background
main {
  background-color: #fff3e7;
  border-top-left-radius: 100% 10%;
  border-top-right-radius: 100% 10%;
  text-align: center;
}

// Form
.form-group {
  position: relative;
}