@import '../../../../assets/styles/abstracts/variables';

// Legenda
.cadastro-instrutor-legend{
    color: $blue-text;
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    margin: 15px 0;

    span{
        color: $red-text;
    }
}

.is-invalid {
    border-bottom: 1.5px solid #dc3545;
}