@import '../../../../assets/styles/abstracts/variables';

// Name
.confirm-name{
  color: $orange-title;
  font-family: 'DK Lemon', sans-serif;
  font-size: 26px;
  letter-spacing: 3px;
  margin-top: 0;
}

// Infos
.confirm-info{
  margin: 15px 0;

  &:first-child{
    margin-top: 0;
  }

  &:last-child{
    margin-bottom: 0;
  }

  .confirm-nickname{
    text-transform: capitalize;
  }

  span{
    display: block;

    &:nth-child(odd){
      color: $blue-title;
      font-family: 'Muli Bold', sans-serif;
      font-size: 18px;
    }

    &::nth-child(even){
      color: $value-text;
      font-family: 'Muli', sans-serif;
      font-size: 17px;
    }
  }
}

.confirm-buttons{
  font-size: 16px;
  margin-top: 40px;

  .confirm,
  .repeat{
    display: inline-block;
    width: 50%;
  }

  .confirm{
    background: $orange-bg;
    color: $white-text;
    font-family: 'Muli Bold', sans-serif;
  }

  .repeat{
    color: $alert-text;
    cursor: pointer;
    font-family: 'Muli', sans-serif;
  }
}