@import "../../../assets/styles/abstracts/variables";

// Main
.agendamentos-main {
  padding: 70px 30px 30px;
  position: relative;

  &:after {
    background: url("../../../assets/images/instrutor/icon-calendar.png");
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    content: "";
    height: 110px;
    left: 50%;
    position: absolute;
    top: -55px;
    transform: translateX(-50%);
    width: 110px;
  }
}

// Card
.agendamentos-card {
  p {
    color: $orange-text;
    font-family: "Muli Bold", sans-serif;
    font-size: 16px;
    margin: 0;

    span{
      color: $value-text;
      font-family: "Muli", sans-serif;
    }
  }
}
