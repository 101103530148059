@import "../../../assets/styles/abstracts/variables";

// Main
.dashboard-main {
  padding: 70px 30px 30px;
  position: relative;

  &:after {
    background: url("../../../assets/images/instrutor/icon-dashboard.png");
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    content: "";
    height: 110px;
    left: 50%;
    position: absolute;
    top: -55px;
    transform: translateX(-50%);
    width: 110px;
  }
}

// Card
.dashboard-card{
  color: $orange-title;
  font-family: 'Muli Bold', sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;

  .number{
    color: $value-text;
    display: block;
    font-size: 28px;
    margin: 10px 0;
  }
}