@import "../../../../assets/styles/abstracts/variables";

// Main
.oficina-instrutor-main {
  padding: 70px 30px 30px;
  position: relative;

  &:after {
    background: url("../../../../assets/images/instrutor/turma-icon.png");
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    content: "";
    height: 110px;
    left: 50%;
    position: absolute;
    top: -55px;
    transform: translateX(-50%);
    width: 110px;
  }
}

// Nome da turma
.oficina-instrutor-class-name {
  border-bottom: 1px solid rgba(112, 112, 112, 0.35);
  color: $blue-title;
  font-family: "DK Aderyn", sans-serif;
  font-size: 26px;
  letter-spacing: 1.3px;
  line-height: 25px;
  margin: 0;
  padding-bottom: 10px;

  span {
    color: $link-text;
    display: block;
    font-family: "Muli Light", sans-serif;
    font-size: 18px;
    letter-spacing: 0;
  }
}

// Texto de Instrução
.oficina-instrutor-instructions {
  color: $green-text;
  font-family: "Muli Bold", sans-serif;
  font-size: 18px;
  line-height: 25px;
  margin: 10px 0;
}

// Timeline
.oficina-instrutor-timeline {
  background: $blue-bg;
  border-radius: 9px;
  margin-bottom: 20px;
  padding: 15px 10px;

  h4 {
    color: $white-text;
    font-family: "Muli Bold", sans-serif;
    font-size: 18px;
    margin: 0 0 8px;
  }
}

// Progresso
.oficina-instrutor-timeline-progress {

  svg {
    fill: $white-bg;

    @media (max-width: 340px) {
      width: 20px;
    }
  }

  div {
    align-items: center;
    display: inline-flex;
    margin: 10px 8px;
  }

  .timeline-list-item {
    background: $white-bg;
    border: 1px solid #f69522;
    border-radius: 50%;
    color: $black-text;
    display: inline-block;
    font-family: "Muli Bold", sans-serif;
    font-size: 16px;
    height: 28px;
    width: 28px;

    @media (max-width: 340px) {
      font-size: 15px;
      height: 24px;
      width: 24px;
    }

    &.active {
      background: $orange-button;
      border-color: $white-bg;
      color: $white-text;
    }
  }

  .timeline-list-item-divider {
    background: $orange-bg;
    height: 2px;
    margin: 0 3px;
    width: 10px;

    &:last-child{
        display: none;
    }

    @media (max-width: 340px) {
      width: 4px;
    }
  }
}

// Timeline - Text
.timeline-instructions {
  color: $white-text;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 19px;
}

// Oficina Content
.oficina-instrutor-content-title {
  color: $orange-title;
  cursor: pointer;
  display: initial;
  font-family: "DK Aderyn", sans-serif;
  font-size: 26px;
  letter-spacing: 1.3px;
  margin: 20px 0;
  position: relative;
  text-decoration: underline;
  text-transform: uppercase;

  &:before {
    background: $blue-bg;
    border-radius: 50%;
    content: "i";
    color: $white-text;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    height: 18px;
    position: absolute;
    right: -20px;
    text-transform: lowercase;
    width: 18px;
  }
}

// Card - Header
.conteudo-treinamento-card-title {
  align-items: center;
  display: flex;
  justify-content: space-between;

  h3 {
    color: $blue-title;
    display: inline-block;
    font-family: "Dk Aderyn", sans-serif;
    font-size: 22px;
    letter-spacing: 1px;
    margin: 0;
    text-align: left;
  }

  span {
    border: solid #2699fb;
    border-width: 0 3px 3px 0;
    display: inline-block;
    height: 11px;
    transform: rotate(-45deg);
    width: 11px;
  }
}

// Carousel
.conteudo-treinamento-card-text {
  // Nav
  .owl-nav {
    bottom: -9px;
    color: #222222;
    display: flex;
    font-size: 30px;
    justify-content: space-around;
    position: absolute;
    width: 100%;
  }

  // Dots
  .owl-dots {
    margin-top: 15px;
  }

  .owl-dot {
    background: #eeeeee !important;
    border: 1px solid #707070 !important;
    border-radius: 50%;
    height: 10px;
    margin: 0 20px;
    width: 10px;

    &:first-child,
    &:last-child {
      margin: 0;
    }

    &.active {
      border-color: #515151 !important;
      background: #515151 !important;
    }
  }
}

// Carousel
.menu-list {
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.menu-item {
  color: $blue-text;
  font-family: "Muli Light", sans-serif;
  font-size: 16px;
  
  &.active {
    border-bottom: 2px solid #3e68b2;
    font-weight: bold;
  }
}

// Ações da oficina
.oficina-instrutor-actions {
  margin-top: 20px;
  padding: 15px;

  h4 {
    color: $link-text;
    font-family: "Muli Bold", sans-serif;
    font-size: 18px;
    margin: 0 0 15px;
  }

  .actions-buttons-line {
    display: flex;
    justify-content: space-between;

    &:last-child {
      margin-top: 15px;
    }
  }

  .actions-button {
    background: $blue-bg;
    border-radius: 30px;
    color: $white-text;
    display: inline-block;
    font-family: "Muli Bold", sans-serif;
    font-size: 12px;
    padding: 5px 0;
    text-transform: uppercase;
    width: 45%;

    &.disabled{
      background: $disabled-button;
      pointer-events: none;
    }
  }
}

// Buttons
.oficina-instrutor-edit-button {
  border: 2px solid $alert-text;
  color: $alert-text;
  font-size: 14px;
  margin: 20px 0;
  padding: 9px 0;
}

// Butões de steps
.oficina-instrutor-step-buttons {
  margin-top: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;

  button {
    width: 45%;
    color: $white-text;
  }

  .oficina-instrutor-button-next {
    background: $green-bg;
  }
  
  .oficina-instrutor-button-previous {
    background: $yellow-bg;
  }

}

// Sweet Alert
.confirm-button-class,
.cancel-button-class {
  margin: 0.3125em;
  min-width: 140px;
  outline-style: none;
}

.confirm-button-class {
  background: $green-bg;
  color: $white-text;
  padding: calc(0.625em + 2px) 2em;

  a {
    color: white !important;
  }
}

.cancel-button-class {
  border: 2px solid $green-bg;
  padding: 0.625em 2em;
  color: $green-text !important;
}
#swal2-title {
  margin-top: 0.5rem !important;
}

.swal2-content {
  margin-top: 0.5rem;
  color: #545454 !important;
}
