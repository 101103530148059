@import '../../../assets/styles/abstracts/variables';

.cadastro-instrutor{
    .header-user{
        display: none;
    }
}

// Header
.cadastro-instrutor-header{
    padding-top: 70px;
    text-align: center;

    .cadastro-instrutor-title{
        color: $page-title;
        font-family: 'DK Lemon', sans-serif;
        font-size: 29px;
        letter-spacing: 3px;
        margin-bottom: 5px;
    }

    span{
        background: $title-border;
        display: block;
        height: 2px;
        margin: 0 auto 78px;
        width: 110px;
    }
}

// Main
.cadastro-instrutor-main{
    position: relative;
    padding: 55px 30px 30px;

    &:after{
        background: url('../../../assets/images/instrutor/cadastro-icon.png');
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        height: 110px;
        left: 50%;
        position: absolute;
        top: -55px;
        transform: translateX(-50%);
        width: 110px;
    }

    @media(max-width: 380px){
        padding: 55px 15px 20px;
    }
}

.cadastro-instrutor-main-title{
    color: $orange-title;
    font-family: 'DK Aderyn', sans-serif;
    font-size: 26px;
    letter-spacing: 1.5px;
}

// Card
.cadastro-instrutor-card{
    margin-bottom: 18px;
    padding: 10px 0;
}

// Progresso
.cadastro-instrutor-progress{
    margin-bottom: 15px;

    svg{
        fill: #3e68b2;
    }
}

.cadastro-instrutor-progresso-list{
    align-items: center;
    display: inline-flex;
    margin: 0 8px;
}

.progresso-list-item{
    border: 1px solid #F69522;
    border-radius: 50%;
    color: $progresso-item;
    display: inline-block;
    font-family: 'Muli Bold', sans-serif;
    font-size: 16px;
    height: 28px;
    width: 28px;

    &.active{
        background: $orange-button;
        color: $white-text;
    }
}

.progresso-list-item-divider{
    background: $orange-bg;
    height: 2px;
    margin: 0 3px;
    width: 10px;
}

// Form
.cadastro-instrutor-label{
    color: $label-text;
    display: block;
    font-family: 'Muli Bold', sans-serif;
    font-size: 18px;

    span{
        color: $red-text;
    }
}

.cadastro-instrutor-alert{
    color: $red-text;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: italic;
}

// Input
.cadastro-instrutor-input{
    border-bottom: 0.5px solid #000000;
    color: $input-text;
    display: block;
    font-family: 'Muli Light', sans-serif;
    font-size: 13px;
    line-height: 25px;
    margin: 0 auto 20px;
    outline: none;
    text-align: center;
    width: 60%;

    &.endereco,
    &.email{
        width: 60%;
    }

    &.cep,
    &.numero{
        width: 60%;
    }
}

// Select
.cadastro-instrutor-select-arrow{
    border-bottom: 0.5px solid #000000;
    cursor: pointer;
    margin: 15px auto;
    width: 60%;

    &:after{
        content: '';
        left: 75%;
        padding: 13px 0 0 0;
        pointer-events: none;
        position: absolute;
        top: 37px;
        transform: rotate(45deg);
        width: 16px;
        border: solid #2699FB;
        border-width: 0 3px 3px 0;
    }

    .cadastro-instrutor-select{
        color: $input-text;
        font-family: 'Muli Light', sans-serif;
        font-size: 13px;
        padding-bottom: 10px;
        text-align: center;
        appearance: none;
        position: relative;
        text-align-last: center;
        vertical-align: middle;
        width: 100%;
        outline: none;
    }
}


// Input Radio
.cadastro-instrutor-input-radio {
	box-sizing: border-box;
	display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 20px;

	input {
        height: 0;
        left: -9999px;
        position: absolute;
        width: 0;
    }

	input + label {
        background-color: #FFF;
        border: 1px solid #3E68B2;
        border-radius: 5px;
        box-sizing: border-box;
        color: $blue-text;
        cursor: pointer;
        display: inline-block;
        font-family: 'Muli', sans-serif;
        font-size: 14px;
        line-height: 140%;
        margin: 0;
        padding: 3px 0;
        position: relative;
        text-align: center;
        width: 105px;
    }

	input:checked + label {
		background-color: $blue-button;
		color: #FFF;
		z-index: 1;
	}
}

// Buttons
.cadastro-instrutor-buttons{
    display: flex;
    justify-content: space-between;
}

.cadastro-instrutor-back,
.cadastro-instrutor-next{
    border-radius: 30px;
    cursor: pointer;
    font-family: 'Muli Bold', sans-serif;
    font-size: 16px;
    height: 40px;
    text-transform: uppercase;
    width: 150px;
    outline-style: none;
}

.cadastro-instrutor-back{
    align-items: center;
    border: 2px solid #87BF40;
    display: inline-flex;
    color: $green-text;
    justify-content: center;
}

.cadastro-instrutor-next{
    background: $green-bg;
    color: $white-text;
}