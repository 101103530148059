@import '../abstracts/variables';

.page-title-header{
    padding-top: 70px;
    text-align: center;

    .page-title{
        color: $page-title;
        font-family: 'DK Lemon', sans-serif;
        font-size: 29px;
        letter-spacing: 3px;
        margin: 20px 0 5px;
    }

    .page-title-border{
        background: $title-border;
        display: block;
        height: 2px;
        margin: 0 auto 75px;
        width: 110px;
    }
}