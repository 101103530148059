@import "../../../../../assets/styles/abstracts/variables";

.listaDeImagens_section {
    background: #ffffff;
    border-radius: 9px;
    box-shadow: 0 3px 6px #00000029;
    padding: 15px 20px;
    font-family: "Muli Light", sans-serif;
}

.listaDeImagens-turma-oficina-content {
    display: none!important;
}

.listaDeImagens_div > p {
    @media(max-width: 768px){
        display: flex;
        flex-direction: column;
        align-items: center;   
    }

    @media(max-width: 425px){
        font-size: 1rem; 
    }

    color: #F69522;
    font-weight: bold;
    font-size: 1.2rem;
}

.listaDeImagens_div > div {

    @media (max-width: 640px) {
        font-size: 12px;
    }
}

.listaDeImagens_div > p > .listaDeImagens_sendButton {
    border-radius: 30px;
    padding: 5px 40px;
    float: right;
    color: white;
    background: rgba(195, 195, 195, 0.698);
    margin-left: 10px;
    margin-top: 15px;  
    cursor: pointer;
    
    button {
        cursor: pointer;
    }
}

.listaDeImagens_div > div > p {
    @media(max-width: 425px){
        font-size: 0.8rem; 
    }

    font-size: 1rem;
}

.listaDeImagens_ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: none;

    @media(max-width: 647px){
        justify-content: center;
    }

    li {
        margin: 15px;
    }
}

.image-limit-size-warn {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 640px) {
        justify-content: center;
    }

    p {
        margin: 4px;
        @media (max-width: 640px) {
            font-size: 12px;
        }
    }
}

.swal2-close {
    color: #afafaf!important;
    font-size: 1.5rem!important;


}

.swal2-close:hover {
    color:rgba(255, 108, 108, 0.698)!important;
}