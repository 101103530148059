@import "../../../../assets/styles/abstracts/variables";

// Titles
.modal-title {
  color: $blue-title;
  font-family: "DK Aderyn", sans-serif;
  font-size: 26px;
  letter-spacing: 1.3px;
  margin-bottom: 0;
}

// Context
.modal-context {
  color: $value-text;
  font-family: "Muli Bold", sans-serif;
  font-size: 16px;
}

.modal-content {
  // Card - Header
  .modal-card-title {
    align-items: center;
    display: flex;
    justify-content: space-between;

    h3 {
      color: $white-text;
      display: inline-block;
      font-family: "Dk Aderyn", sans-serif;
      font-size: 22px;
      letter-spacing: 1px;
      margin: 0;
      text-align: left;
    }

    span {
      background-color: $blue-button;
      border-width: 0 3px 3px 0;
      display: inline-block;
      height: 11px;
      transform: rotate(-45deg);
      width: 11px;
    }
  }

  .answer-button {
    background-color: $green-button;
    color: $white-text;
    border-radius: 30px;
    cursor: pointer;
    display: block;
    font-family: 'Muli Bold', sans-serif;
    font-size: 16px;
    padding: 10px 0;
    width: 100%;
    margin: 10px auto;
    text-align: center;
    text-transform: uppercase;
    outline-style: none;
  }
}
