@import "../../assets/styles/abstracts/variables";

.main-header {
  align-items: center;
  background: $white-bg;
  box-shadow: 0 1px 4px #00000029;
  display: flex;
  height: 70px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.header-arrow,
.header-ifa,
.header-user {
  position: absolute;
}

.header-arrow {
  cursor: pointer;
  left: 35px;
  width: 25px;
}

.header-ifa {
  left: 50%;
  transform: translateX(-50%);
  width: 38px;
}

.header-user {
  border: 1px solid #333;
  border-radius: 50%;
  height: 40px;
  right: 0;
  width: 40px;

  &.instructor {
    background: #a7a7a7;
    border: 3px solid #a7a7a7;
    fill: $white-bg;
  }
}

.header-dropdown {
  position: absolute;
  height: 40px;
  right: 35px;
  width: 40px;

  input {
    height: 0;
    left: -9999px;
    position: absolute;
    width: 0;
  }

  input + label {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    height: 40px;
    line-height: 140%;
    margin: 0;
    position: relative;
    text-align: center;
    width: 40px;
  }

  input + label .dropdown-list {
    background: $white-bg;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    display: none;
    padding: 10px 15px;
    position: relative;
    right: 130px;
    text-align: left;
    top: 35px;
    width: 190px;

    li{
      border-top: 1px solid rgba(0, 0, 0, .2);
      color: $orange-text;
      display: block;
      font-family: 'Muli Bold', sans-serif;
      font-size: 14px;
      letter-spacing: 0.5px;
      padding: 5px 0;
      text-transform: uppercase;

      &:first-child{
        border: none;
      }

      a{
        align-items: center;
        color: $orange-text;
        display: flex;
      }
    }
  }

  input:checked + label .dropdown-list {
    display: block;
  }
}

// Item
.header-item-icon{
  fill: $orange-bg;
  height: 20px;
  margin-right: 10px;
  width: 20px;
}