@import "../../../../assets/styles/abstracts/variables";

// Main
.estrelas-instrutor-main {
  padding: 70px 30px 30px;
  position: relative;

  &:after {
    background: url("../../../../assets/images/instrutor/turma-icon.png");
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    content: "";
    height: 110px;
    left: 50%;
    position: absolute;
    top: -55px;
    transform: translateX(-50%);
    width: 110px;
  }
}

// Nome da turma
.estrelas-instrutor-class-name {
  border-bottom: 1px solid rgba(112, 112, 112, 0.35);
  color: $blue-title;
  font-family: "DK Aderyn", sans-serif;
  font-size: 26px;
  letter-spacing: 1.3px;
  line-height: 25px;
  margin: 0;
  padding-bottom: 10px;

  span {
    color: $link-text;
    display: block;
    font-family: "Muli Light", sans-serif;
    font-size: 18px;
    letter-spacing: 0;
  }
}

// Intro Text
.estrelas-instrutor-text {
  color: $green-text;
  font-family: "Muli Bold", sans-serif;
  font-size: 18px;
  line-height: 25px;
}

// Legenda das estrelas
.estrelas-instrutor-legend {
  h3 {
    color: $blue-title;
    font-family: "Muli Bold", sans-serif;
    font-size: 16px;
    margin: 0 0 10px;
  }

  span {
    color: $blue-text;
    display: inline-block;
    font-family: 'Muli Bold', sans-serif;
    font-size: 13px;
    width: 50%;
  }

  .star {
    width: 22px;

    &.half {
      fill: $orange-bg;
    }

    &.full {
      fill: $green-bg;
    }
  }

  .ifa{
    width: 18px;
  }
}

// List
.estrelas-instrutor-list-header {
  display: flex;
  justify-content: space-around;

  .title {
    color: $orange-text;
    font-family: "Muli", sans-serif;
    font-size: 18px;
  }
}

// List Item
.estrelas-instrutor-list-item {
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
  display: flex;
  padding: 10px 0;

  .item-info {
    padding-bottom: 10px;
  }
  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: none;
    padding-bottom: 0;
  }

  .participant-info,
  .star-info {
    display: inline-block;
  }

  .participant-info {
    width: 60%;

    span {
      color: $value-text;
      display: block;
      font-family: "Muli", sans-serif;
      font-size: 15px;
      text-align: left;

      &:first-child {
        color: $blue-text;
      }
    }
  }

  .star-info {
    width: 40%;

    .icon,
    span {
      display: block;
    }

    .icon {
      margin: 0 auto;
      width: 40px;

      &.incomplete {
        fill: #fff;
        stroke: $disabled-button;
        stroke-dasharray: 2.5;
      }

      &.completed {
        fill: $green-bg;
      }

      &.later {
        fill: $orange-bg;
      }
    }

    span {
      background: $green-bg;
      border-radius: 30px;
      color: $white-text;
      cursor: pointer;
      font-family: "Muli Bold", sans-serif;
      font-size: 9px;
      padding: 5px 10px;
      text-transform: uppercase;
    }
  }
}

// Button
.estrelas-instrutor-button {
  background: $green-bg;
  color: $white-bg;
  margin-top: 30px;
}

li.estrelas-instrutor-list-item {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
}

li.estrelas-instrutor-list-item div {
  flex: 1;
}

li.estrelas-instrutor-list-item .participant-info {
  flex: 2;
  min-width: 100px;
}
