@import '../../../../assets/styles/abstracts/variables';

// Main
.missoes-instrutor-main{
    padding: 70px 30px 30px;
    position: relative;

    &:after{
        background: url('../../../../assets/images/instrutor/turma-icon.png');
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 50%;
        content: '';
        height: 110px;
        left: 50%;
        position: absolute;
        top: -55px;
        transform: translateX(-50%);
        width: 110px;
    }
}

// Nome da turma
.missoes-instrutor-class-name{
    border-bottom: 1px solid rgba(112, 112, 112, .35);
    color: $blue-title;
    font-family: 'DK Aderyn', sans-serif;
    font-size: 26px;
    letter-spacing: 1.3px;
    line-height: 25px;
    margin: 0;
    padding-bottom: 10px;

    span{
        color: $link-text;
        display: block;
        font-family: 'Muli Light', sans-serif;
        font-size: 18px;
        letter-spacing: 0;
    }
}

// Intro Text
.missoes-instrutor-text{
    color: $green-text;
    font-family: 'Muli Bold', sans-serif;
    font-size: 18px;
    line-height: 25px;
}

// List
.missoes-instrutor-participants{
    .list-header{
        color: $orange-title;
        display: block;
        font-family: 'Muli Bold', sans-serif;
        font-size: 18px;

        span{
            display: inline-block;

            &:first-child{
                text-align: left;
                width: 60%;
            }

            &:last-child{
                width: 40%;
            }
        }
    }

    .participants-list-item{
        border-bottom: 1px solid rgba(112, 112, 112, .5);
        padding: 10px 0;

        &:first-child{
            padding-top: 0;
        }

        &:last-child{
            border: none;
        }

        .participants-info,
        .participants-mission{
            display: inline-block;
        }

        .participants-info{
            text-align: left;
            width: 60%;

            .name{
                color: $blue-text;
                display: block;
            }
        }

        .participants-mission{
            width: 40%;

            .mission{
                color: $green-text;
                display: block;
            }

            .button{
                background: $green-bg;
                border-radius: 30px;
                color: $white-text;
                cursor: pointer;
                display: block;
                font-family: 'Muli Bold', sans-serif;
                font-size: 9px;
                padding: 5px 10px;
                text-transform: uppercase;
            }
        }
    }
}

// Button
.missoes-instrutor-finish{
    color: $white-text;
    background-color: $green-bg;
}