.home-instrutor-license-warn {
    justify-content: space-between;
    display: flex;
    align-items: center;
    background-color: #faeeb0;
    margin: 10px 2% 10px 2%;
    color: #8B4513;
    padding: 8px 0 8px 0;
  }
  
  .home-instrutor-license-warn-content {
    text-align: center;
    flex-grow: 1;
    padding-left: 4px;
  }
  
  .home-instrutor-license-warn-icon {
    color: #8B4513;
    font-weight: bold;
    margin-right: 6px;
  }
  
  .home-instrutor-license-warn-react-icon {
    position: relative;
    top: -2px;
    margin-right: 6px;
    height: 10px;
    width: 10px;
  }
  
  .home-instrutor-license-warn-date {
    color: #8B4513;
    font-weight: bold;
    margin-right: 6px;
  }
  
  .license-warn-close-button {
    margin-top: -1px;
    cursor: pointer;
  }