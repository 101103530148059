@import "../../../../assets/styles/abstracts/variables";

// Main
.ranking-main {
  min-height: calc(100vh - 200px);
  padding: 70px 30px 30px;
  position: relative;

  &:after {
    background: url("../../../../assets/images/participante/icon-ranking.png");
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    content: "";
    height: 110px;
    left: 50%;
    position: absolute;
    top: -55px;
    transform: translateX(-50%);
    width: 110px;
  }
}

// Text
.ranking-main-text{
  color: $blue-text;
  font-family: 'Muli Bold', sans-serif;
  font-size: 18px;
  line-height: 23px;
}

// Legenda das estrelas
.ranking-legend{
  h3{
    color: $blue-title;
    font-family: 'Muli Bold', sans-serif;
    font-size: 16px;
    margin: 0 0 10px;
  }

  span{
    color: $blue-text;
    display: inline-block;
    font-family: 'Muli Bold', sans-serif;
    font-size: 13px;
    width: 32%;
  }

  .star{
    width: 22px;

    &.half{
      fill: $orange-bg;
    }

    &.full{
      fill: $green-bg;
    }
  }

  .ifa{
    width: 18px;
  }
}

// Ranking
.ranking-podium {
  align-items: flex-end;
  display: flex;
  margin: 0 5%;
  position: relative;

  .ranking-podium-item {
    width: 30%;

    &:nth-child(2) {
      margin: 0 5%;
    }

    .avatar{
      border: 1px solid blue;
      border-radius: 50%;
      display: block;
      height: 50px;
      margin: 0 auto 5px;
      width: 50px;
    }

    .name,
    .points {
      color: $blue-text;
      display: block;
      font-family: "Muli Bold", sans-serif;
    }

    .name {
      font-size: 16px;
    }

    .points {
      display: flex;
      align-items: center;
      font-size: 12px;
      justify-content: center;

      .ifa-icon {
        margin-right: 5px;
        width: 15px;
      }
    }
  }

  .ranking-podium-base {
    align-items: center;
    border: 1px solid #707070;
    border-bottom-color: transparent;
    border-radius: 4px 4px 0 0;
    display: flex;
    justify-content: center;

    &.first {
      background: $green-bg;
      height: 120px;
    }

    &.second {
      background: $purple-bg;
      height: 90px;
    }

    &.third {
      background: $purple-bg;
      height: 70px;
    }

    .placing {
      color: $white-text;
      display: block;
      font-family: "Muli Bold", sans-serif;
      font-size: 54px;
    }
  }
}

// List
.ranking-content {
  padding: 5px 20px 15px;

  .placing,
  .name,
  .points {
    display: inline-block;
  }

  .placing {
    color: $orange-text;
    font-family: 'Muli Bold', sans-serif;
    font-size: 24px;
    width: 15%;
  }

  .name {
    color: $value-text;
    font-family: 'Muli ', sans-serif;
    font-size: 15px;
    text-align: left;
    width: 65%;

    img{
      border: 1px solid red;
      border-radius: 50%;
      display: inline-flex;
      height: 30px;
      margin-right: 5px;;
      width: 30px;
    }
  }

  .points {
    align-items: center;
    color: $blue-text;
    display: flex;
    font-family: 'Muli Bold', sans-serif;
    font-size: 13px;
    justify-content: center;
    width: 20%;
  }
}

// List
.ranking-list {
  margin: 0;
}

// List Item
.ranking-list-item {
  align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
  display: flex;
  margin: 10px 0;
  padding: 5px 0;

  &:last-child {
    border: none;
    margin-bottom: 0;
  }

  .ifa-icon {
    margin-right: 5px;
    width: 15px;
  }
}
