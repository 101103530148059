@import "../../../../assets/styles/abstracts/variables";

.recover{
  text-align: center;

  .form-group {
    &:nth-child(3){
      margin: 25px auto;
    }
  }
}

// Main
.recover-main {
  padding: 30px 40px;
  width: 100%;
}

// Text
.recover-text{
  color: $blue-text;
  font-family: 'Muli', sans-serif;
  font-size: 19px;
  line-height: 26px;
}

// Label e Input
.recover-label,
.recover-input{
  display: block;
}

.recover-label{
  color: $label-text;
  font-family: 'Muli Bold', sans-serif;
  font-size: 18px;
}

.recover-input{
  border-bottom: 0.5px solid #000000;
  color: $link-text;
  font-family: 'Muli Light', sans-serif;
  font-size: 13px;
  line-height: 35px;
  margin: 0 auto;
  width: 80%;
  text-align: center;
}

// Button
.recover-btnRecover{
  background: $orange-bg;
  color: $white-text;
  margin: 45px 0 25px;
}

// Back Link
.recover-backLink{
  color: $blue-text;
  font-family: 'Muli', sans-serif;
  font-size: 16px;
}