@import "../../../assets/styles/abstracts/variables";

// Main
.poderes-main {
  min-height: calc(100vh - 200px);
  padding: 70px 30px 30px;
  position: relative;

  &:after {
    background: url("../../../assets/images/participante/icon-poderes-main.png");
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    content: "";
    height: 110px;
    left: 50%;
    position: absolute;
    top: -55px;
    transform: translateX(-50%);
    width: 110px;
  }
}

.poderes-image{
    margin: 10px 0;
    width: 45%;

    &:nth-child(odd){
        margin-right: 5%;
    }

    &.disabled{
        border: 3px solid #fb5d00;
        border-style: dashed;
        opacity: .3;
    }
}

.open-modal{
  cursor: pointer;
}