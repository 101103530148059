@import '../../../../../assets/styles/abstracts/variables';

.poderes-list-item{
    cursor: pointer;
    display: inline-block;
    margin-bottom: 10px;
    width: 20%;

    @media(max-width: 360px){
        width: 25%;
    }

    .name,
    .quantity{
        display: block;
    }

    .name{
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 3px;
        margin-left: 1px;

        &.Transformer,
        &.Transformador{
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }


    .quantity{
        align-items: center;
        background-color: $white-bg;
        border: 1px solid $orange-bg;
        border-radius: 50%;
        color: $orange-text;
        display: flex;
        font-family: 'Muli Bold', sans-serif;
        font-size: 18px;
        height: 40px;
        justify-content: center;
        margin: 0 auto;
        width: 40px;
    }
}

// Modal
// Content
.poderes-modal-content{
    height: 100%;
}

// Image
.modal-content-image{
    width: 200px;
}

// Title
.modal-content-title{
    color: $orange-title;
    font-family: 'DK Lemon', sans-serif;
    font-size: 29px;
    letter-spacing: 3.5px;
    margin: 10px 0;
}

// Text
.modal-content-text{
    color: $poder-text;
    font-family: 'Muli', sans-serif;
    font-size: 14px;
    line-height: 20px;
}