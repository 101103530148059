@import "../../../../assets/styles/abstracts/variables";

// Avatar Item
.hair-item {
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  height: 70px;
  margin: 30px 20px 0 0;
  min-width: 70px;

  .input-color,
  .input-noHair,
  .input-withHair {
    height: 0;
    left: -9999px;
    position: absolute;
    width: 0;
  }

  .input-color + .label-color {
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 70px;
    width: 70px;
  }

  .input-color:checked + .label-color {
    border: 3px solid $orange-bg;
    color: #fff;
    z-index: 1;
  }

  .input-noHair + .label-noHair{
    border: 1px solid $green-bg;
    border-radius: 17px;
    cursor: pointer;
    display: block;
    color: $green-text;
    font-family: "Muli", sans-serif;
    font-size: 16px;
    padding: 3px 0;
    text-transform: uppercase;
    width: 170px;
  }

  .input-noHair:checked + .label-noHair {
    background: $green-bg;
    color: #fff;
    z-index: 1;
  }

  &.item-noHair{
    height: 32px;
    margin: 40px 0 20px;
  }

  .input-withHair + .label-withHair{
    border: 1px solid $green-bg;
    border-radius: 17px;
    cursor: pointer;
    display: block;
    color: $green-text;
    font-family: "Muli", sans-serif;
    font-size: 16px;
    padding: 3px 0;
    text-transform: uppercase;
    width: 170px;
  }

  .label-withHair{
    margin-left: 10px;
  }

  .input-withHair:checked + .label-withHair {
    background: $green-bg;
    color: #fff;
    z-index: 1;
  }

  &.item-withHair{
    height: 32px;
    margin: 40px 0 20px;
  }

}

// Highlight Images
.avatar {
  display: block;
  z-index: 99;
}

.baldAvatar {
  display: none;
  z-index: 9;
}