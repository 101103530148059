@import "../../../../assets/styles/abstracts/variables";

// Main
.sendMission-main {
  min-height: calc(100vh - 200px);
  padding: 70px 30px 30px;
  position: relative;

  &:after {
    background: url("../../../../assets/images/instrutor/sendmissions-icon.png")!important;
    background-repeat: no-repeat!important;
    background-size: contain!important;
    border-radius: 50%;
    content: "";
    height: 110px;
    left: 50%;
    position: absolute;
    top: -55px;
    transform: translateX(-50%);
    width: 110px;
  }
}

.turma-class-name {
  line-height: 40px!important;
}
