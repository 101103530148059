@import "../../../../../assets/styles/abstracts/variables";

// Titles
.mission-title {
  color: $blue-title;
  font-family: "DK Aderyn", sans-serif;
  font-size: 26px;
  letter-spacing: 1.3px;
  margin-bottom: 0;
}

// Context
.mission-context {
  color: $value-text;
  font-family: "Muli Bold", sans-serif;
  font-size: 16px;
}

.modal-content {
  // Card
  .modal-card {
    background: $blue-bg;
    border-radius: 9px;
    box-shadow: 0 3px 6px #00000029;
    margin-bottom: 15px;
    padding: 15px;

    input {
      display: none;
      visibility: hidden;
    }

    .modal-card-text {
      border-top: 1px solid rgba(255, 255, 255, 0.4);
      color: $white-text;
      display: none;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 20px;
      overflow: hidden;
      padding-top: 10px;
      transition: display 1000ms ease;
    }

    input:checked ~ .modal-card-text {
      display: block;
    }
  }

  // Card - Header
  .modal-card-title {
    align-items: center;
    display: flex;
    justify-content: space-between;

    h3 {
      color: $white-text;
      display: inline-block;
      font-family: "Dk Aderyn", sans-serif;
      font-size: 22px;
      letter-spacing: 1px;
      margin: 0;
      text-align: left;
    }

    span {
      border: solid $white-bg;
      border-width: 0 3px 3px 0;
      display: inline-block;
      height: 11px;
      transform: rotate(-45deg);
      width: 11px;
    }
  }
}
