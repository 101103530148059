@import '../../../../assets/styles/abstracts/variables';

.cadastro-instrutor-confirmacao{
    text-align: left;

    .confirmacao-title{
        text-align: center;
    }

    .cadastro-instrutor-card{
        padding: 17px 20px;
    }
}

// Header Cards
.cadastro-confirmacao-header{
    display: flex;
    justify-content: space-between;

}

.cadastro-confirmacao-title{
    color: $orange-title;
    display: inline-block;
    font-family: 'DK Aderyn', sans-serif;
    font-size: 18px;
    letter-spacing: 1px;
    margin: 0;
}

.cadastro-confirmacao-edit{
    border: 1px solid $green-text;
    border-radius: 30px;
    color: $green-text;
    cursor: pointer;
    font-family: 'Muli Bold', sans-serif;
    font-size: 12px;
    padding: 3px 14px;
    text-transform: uppercase;
}

// Informações
.cadastro-confirmacao-label{
    color: $blue-text;
    font-family: 'Muli Bold', sans-serif;
    font-size: 16px;
}

.cadastro-confirmacao-values{
    color: $value-text;
    font-family: 'Muli', sans-serif;
    font-size: 14px;
}

.cadastro-confirmacao-info-line{
    display: block;

    .right-values{
        float: right;
    }
}