@import "../../../../../assets/styles/abstracts/variables";

.cardImagem-item {
  width: 254px;
  height: 124px;
  border: 1px dashed #b8b8b8;
  border-radius: 7px;
  background-image: url("../../../../../assets/images/instrutor/sendmission-image-plate.png");
  background-position: center;
  background-size: 254px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-end;
}

.cardImagem-form {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  color: grey;

  label {
    margin-bottom: 20px;
    color: grey;
    font-size: 0.9rem;
    padding-left: 14px;
    text-align: initial;
  }

  select {
    margin-bottom: 20px;
    padding: 10px;
  }
}

.cardImagem-item > span {
  color: white;
  background: #87bf40;
  font-size: 30px;
  font-weight: bold;
  padding: 0px 15px;

  position: relative;
  left: 21px;
  top: 16px;
  border-radius: 100%;
  cursor: pointer;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

//select
.cardImagem_selectParticipant {
  padding-left: 1px;
}

.cardImagem_inputParticpant {
  padding-left: 5px;
}

.cardImagem-dadosDoParticipante {
  font-size: 0.8rem;
  max-width: 254px;
  display: flex;
  color: #5e6166;
  flex-direction: column;
  font-weight: 700;
  align-items: flex-start;

  .cardImagem-nomeDoParticipante {
    margin-bottom: 10px;
    margin-left: 0px;

    width: 254px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    span {
      position: relative;
      margin-right: 5px;
      /* float: right; */
      background: red;
      border-radius: 100px;
      min-width: 19px;
      height: 20px;
      color: white;
      cursor: pointer;
    }
  }

  .cardImagem-missaoDoParticipante {
    text-align: left;
    margin: 0;
  }
}

//swal config

.swal2-popup {
  width: 22rem;
}

.cardImagem-modalDeleteText {
      font-family: "Muli Light", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.02em;

    color: #5e6166!important;
  }


.cardimagem-modalDeleteConfirmButton {
    box-shadow: none !important;
}

.cardimagem-modalDeleteConfirmButton:hover {
    box-shadow: none !important;
}

.cardimagem-modalDeleteCancelButton {
    border: solid 2px red!important;
    display: inline-block!important;
    background-color: white!important;
    color: red!important;

    &:focus {
        box-shadow: none !important;
    }
}
